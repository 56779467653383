<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: John David Vásquez Serna                                      ###### -->
<!-- ###### @date: julio 2024                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <v-main class="pa-0">
    <v-card>
      <v-card-title class="title pt-3">
        <span>Cambiar estado masivamente</span>
      </v-card-title>
      <v-card-text class="pa-4">
        <v-form ref="archivoEstado">

          <v-autocomplete v-model="rutaMasiva" label="Seleccione una ruta" class="ms-1 me-1" ref="refRutaMasiva"
            outlined dense :items="tiposRuta" :menu-props="menuPropsRuta"
            @keydown="tabulador($event, 'rutaMasiva', 'refRutaMasiva')" no-data-text="Sin resultados"
            :error-messages="estadoRuta" :readonly="leyendoArchivo || tabla">
          </v-autocomplete>

          <div class="archivo ma-1" v-show="verCSV"> 
            <input :disabled="leyendoArchivo || rutaMasiva === null || rutaMasiva === '' || estadoRuta !== ''" 
              type="file" class="seleccionarArchivo" accept=".csv" @change="lectorCSV($event)" ref="cargaDeRelaciones">
            <p v-if="rutaMasiva === null || rutaMasiva === ''" style="padding-top: 0.7rem;">Primero debe seleccionar una
              ruta.</p>
            <p v-else-if="validandoDocumento" style="padding-top: 0.6rem;">
              Espere un momento...
              <v-icon :class="{ 'rotate-animation': validandoDocumento }" large>rotate_right
              </v-icon>
            </p>
            <p v-else-if="formatoIncorrecto" style="color: red; margin-top: 1.4rem;">
              <v-icon class="mr-2" style="color: red;">error</v-icon>
              {{ mensajeIncorrecto }}
            </p>
            <p v-else>Arrastre un archivo .CSV con los datos para relacionar los afiliados a la ruta,
              <br>
              También puede hacer click en este cuadro.
            </p>
          </div>
          <v-alert v-if="tabla" class="pa-2 ma-0" color="primary" transition="scale-transition" outlined>
            <h4 class="d-flex justify-center"><v-icon color="primary" class="mr-2">info</v-icon>{{ mensajeCantidad }}
            </h4>
          </v-alert>
          <v-simple-table class="tablaRelaciones mt-1" fixed-header height="330px" v-show="tabla"
            ref="tablaRelacionesMasivas" id="miTabla">
            <template v-slot:default>
              <thead class="header">
                <tr>
                  <th> TIPO DOCUMENTO </th>
                  <th> DOCUMENTO </th>
                  <th> VALIDACIÓN </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(registro, registroIndex) in registrosFiltrados" :key="registroIndex">
                  <td>{{ registro.tipDoc }}</td>
                  <td>{{ registro.numDoc }}</td>
                  <td>
                    <div v-if="validacionesFiltradas[registroIndex] && validacionesFiltradas[registroIndex].length > 0">
                      <div v-for="(mensaje, subIndex) in validacionesFiltradas[registroIndex]" :key="subIndex"
                        class="error-message">
                        {{ mensaje }}
                      </div>
                    </div>
                    <div v-else>
                      <v-icon color="green">check_circle</v-icon>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <!-- Botones de cerrar y volver que se activan cuando se abre el dialogo para el cambio de estado masivo -->
          <div class="d-flex justify-end mt-2">
            <v-btn color="error" text :disabled="leyendoArchivo" @click="close()">CERRAR</v-btn>
            <v-btn v-if="mostrarBotones" color="blue" text @click="volverACargarDocumento">VOLVER</v-btn>
            <v-btn v-if="mostrarBotones && hayErrores && cantidadRegistros > 0" color="success" text
              @click="descargarResultadoDeValidaciones()">
              DESCARGAR
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>

    <!-- Contenedor para mostrar los mensajes de error -->
    <div v-if="mostrarNotificacion" class="notificacion advertencia">
      <span><v-icon color="#f80505" class="rotate-animation-notification size">highlight_off</v-icon></span>
      <span>{{ mensajeNotificacion }} <br> {{ pathNotificacion }}</span>
    </div>

  </v-main>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
import { mapState } from "vuex";
import Papa from 'papaparse';

export default {
  name: 'EstadoAfiliadoRuta',

  data() {
    return {
      tabla: false,
      verCSV: true,
      validandoDocumento: false,
      formatoIncorrecto: false,
      cantidadRegistros: null,
      registros: [],
      validaciones: [],
      mostrarBotones: false,
      usuario: '',
      mensajeIncorrecto: '',
      leyendoArchivo: false,
      totalBloques: null,
      bloquesProcesados: null,
      bloquesTemporales: [],
      listaDeTiposDocumento: [],
      tiposRuta: [],
      rutaMasiva: null,
      menuPropsRuta: { offsetY: true, maxHeight: 170, maxWidth: null },
      idsAfruList: [],
      cambioDeEstados: '',
      mostrarNotificacion: false,
      mensajeNotificacion: "",
      pathNotificacion: "",
      estadoRuta: '',
    }
  },

  mounted() {
    this.usuario = this.auth.username.toUpperCase();
    this.tiposDeDocumentos();
    this.rutas();
  },

  computed: {
    ...mapState(["auth", "enterprise"]),
    /**
     * Devuelve la cantidad de registros que tienen mensajes de validación.
     * @returns {number} - La cantidad de registros con mensajes de validación.
     */
    registrosConError() {
      const registrosConError = this.validaciones.filter(validacion => validacion.length > 0);
      return registrosConError.length;
    },
    /**
    * Filtra y devuelve los registros que tienen mensajes de validación.
    * Si no hay registros con mensajes de validación, devuelve todos los registros.
    * @returns {Array} - Los registros que tienen mensajes de validación o todos los registros si no hay errores.
    */
    registrosFiltrados() {
      if (this.registrosConError > 0) {
        const registrosFiltrados = this.registros.filter((registro, index) => this.validaciones[index].length > 0);
        return registrosFiltrados;
      }
      return this.registros;
    },
    /**
    * Filtra y devuelve las validaciones que tienen mensajes de error.
    * Si no hay registros con mensajes de validación, devuelve todas las validaciones.
    * @returns {Array} - Las validaciones que tienen mensajes de error o todas las validaciones si no hay errores.
    */
    validacionesFiltradas() {
      if (this.registrosConError > 0) {
        return this.validaciones.filter((validacion, index) => this.validaciones[index].length > 0);
      }
      return this.validaciones;
    },
    /**
    * Verifica si existen errores en las validaciones.
    * @returns {boolean} - true si hay al menos un registro con mensajes de validación, false en caso contrario.
    */
    hayErrores() {
      return this.validaciones.some(validacion => validacion.length > 0);
    },
    /** Cuenta los registros con errores */
    cantidadRegistrosConError() {
      return this.registros.filter((registro, index) => this.validaciones[index].length > 0).length;
    },
    /** Establece un valor en un mensaje para detallar la cantidad de registros procesados y si tienen error */
    mensajeCantidad() {
      const validoText = this.cantidadRegistros === 1 ? 'Se validó' : 'Se validaron';
      const registrosText = this.cantidadRegistros === 1 ? 'registro' : 'registros';
      let mensaje = `${validoText} ${this.cantidadRegistros} ${registrosText}`;
      if (this.cantidadRegistrosConError > 0) {
        const erroresText = this.cantidadRegistrosConError === 1 ? 'presenta el siguiente error' : 'presentan los siguientes errores';
        mensaje += ` y ${this.cantidadRegistrosConError} ${erroresText}.`;
      } else {
        mensaje += '.';
      }
      return mensaje;
    },
  },

  watch: {
    'rutaMasiva': function () {
      const idRuta = (this.rutaMasiva === null || this.rutaMasiva === '') ? null : this.rutaMasiva;
      if (idRuta !== null) {
        const estadoRuta = this.tiposRuta.filter(item => item.value === idRuta)?.estado;
        this.estadoRuta = estadoRuta ? 'La ruta está inactiva.' : '';
      } else {
        this.estadoRuta = '';
      }
    }
  },

  methods: {
    /**
     * Maneja el evento de presionar la tecla Tab para campos v-autocomplete.
     * Si se presiona la tecla Tab y el valor actual del modelo es null,
     * desenfoca el campo y establece el valor del modelo como una cadena vacía.
     *
     * @param {KeyboardEvent} event - El objeto del evento de teclado.
     * @param {string} model - El nombre de la propiedad del modelo.
     * @param {string} ref - El nombre de la referencia del campo v-autocomplete.
     */
    tabulador(event, model, ref) {
      if (event.key === 'Tab' && this[model] === null) {
        this.$refs[ref].blur();
        this[model] = '';
      }
    },
    /**
     * Recupera una lista de tipos de documentos desde el servidor y la almacena en la propiedad
     * 'listaDeTiposDocumento' del componente.
     * @throws {Error} Si ocurre un error durante la solicitud al servidor.
     */
    tiposDeDocumentos() {
      this.listaDeTiposDocumento = [];
      this.$http.get(`msa-administration/tipoDocumento/listar`)
        .then((response) => {
          this.listaDeTiposDocumento = response.data.map(item => ({
            text: `${item.descripcion}`,
            value: item.tipoDocumento,
            tipoDocumento: item.tipoDocumento,
          }))
        }).catch((error) => {
          this.verError(error);
        })
    },
    /**
     * Consulta las rutas y las establece en el array tiposRuta,
     * También define el tamaño del campo por medio de las propiedades del refs.
     */
    rutas() {
      if (this.enterprise.code === 4) {
        this.tiposRuta = [];
        this.$http.get(`msa-administration/api/ruta/list`)
          .then((response) => {
            this.tiposRuta = response.data.map(item => ({
              text: `${item.idRuta} - ${item.descripcionRuta}`,
              value: item.idRuta,
              estado: item.eliminado,
            }))
          }).catch((error) => {
            this.verError(error);
          })
      }
      setTimeout(() => {
        this.$nextTick(() => {
          if (this.$refs.refRutaMasiva) {
            this.menuPropsRuta.maxWidth = this.$refs.refRutaMasiva.$el.offsetWidth;
          }
        })
      }, 100)
    },
    /**
     * Lee y procesa un archivo CSV subido por el usuario.
     * Verifica el formato del archivo, analiza su contenido y realiza validaciones.
     * 
     * @param {Event} event - El evento de subida de archivo.
     */
    lectorCSV(event) {
      this.leyendoArchivo = true;
      this.formatoIncorrecto = false;
      this.mensajeIncorrecto = '';
      const file = event.target.files[0];
      
      // Verificar si el archivo tiene la extensión .csv
      if (!file || file.name.split('.').pop() !== 'csv') {
        this.formatoIncorrecto = true;
        this.mensajeIncorrecto = 'Formato incorrecto, debe subir o arrastrar un archivo .csv.';
        this.leyendoArchivo = false;
        return;
      }

      this.validandoDocumento = true;
      this.registros = [];
      this.registrosTemporales = [];

      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target.result;
        const parsedData = Papa.parse(content, {
          header: false,
          delimiter: ';',
          encoding: 'ISO-8859-1',
        });
        
        if (!parsedData.data || parsedData.data.length === 0 || (parsedData.data.length === 1 && parsedData.data[0].length === 1 && parsedData.data[0][0] === '')) {
          this.formatoIncorrecto = true;
          this.mensajeIncorrecto = 'El archivo está vacío. Por favor, suba un archivo con contenido.';
          this.validandoDocumento = false;
          this.leyendoArchivo = false;
          return;
        }

        // Verificar que el archivo tenga al menos 1000 registros y que tenga al menos 25 columnas
        const primeraFila = parsedData.data[0];
        if (parsedData.data.length < 1001 || primeraFila.length < 25) { // 1000 registros + 1 fila de encabezado
          this.formatoIncorrecto = true;
          this.mensajeIncorrecto = 'El archivo debe tener al menos 1.000 registros y 25 columnas.';
          this.validandoDocumento = false;
          this.leyendoArchivo = false;
          return;
        }
        
        const registros = [];
        const headerRow = parsedData.data[0]; // Primera fila del archivo.
        const isHeaderRow = /^[a-zA-Z]+$/.test(headerRow[0]) && /\d/.test(headerRow[1]);
        // Inicio en 1 si es una fila de encabezados, de lo contrario empiezo en 0.
        for (let index = isHeaderRow ? 0 : 1; index < parsedData.data.length - 1; index++) {
          const row = parsedData.data[index];
          if (row[0] !== '' || row[1] !== '') {
            registros.push({
              tipDoc: row[0],
              numDoc: row[1],
            });
          }
        }
        if (registros.length === 0) {
          this.formatoIncorrecto = true;
          this.mensajeIncorrecto = 'El archivo está vacío. Por favor, suba un archivo con contenido.';
          this.validandoDocumento = false;
          this.leyendoArchivo = false;
          return;
        }
        this.cantidadRegistros = registros.length;
        this.registros = registros;
        this.registrosTemporales.push([...registros]);
        this.archivoSubido = true;
        this.validaciones = Array.from({ length: this.registros.length }, () => []);
        this.registros = [];
        const backendCalls = [
          this.validarCargueMensual(file),
        ];
        Promise.all(backendCalls)
          .then(() => {
            this.$nextTick(() => {
              this.verificarValidaciones();
            });
          })
          .catch((error) => {
            this.verError(error);
            this.validandoDocumento = false;
            this.mostrarBotones = false;
            this.leyendoArchivo = false;
          });
      };
      reader.readAsText(file, 'ISO-8859-1');
    },
    /**
     * Envia el archivo .csv para verificar sus datos y procesarlos en segundo plano sin esperar una respuesta.
     * 
     * @param file - archivo csv que se envia al back
     */
    validarCargueMensual(file) {
      const formData = new FormData();
      formData.append('file', file);

      return this.$http
        .post(`msa-administration/api/historialAfilRuta/upload/${this.enterprise.code}/${this.rutaMasiva}/${this.usuario.toLowerCase()}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data; charset=ISO-8859-1', 
          },
        })
        .then((response) => {
          this.filasSeleccionadas = [];
          if (Array.isArray(response.data) && response.data.length > 0) {
            response.data.forEach((mensaje, index) => {
              if (mensaje.trim() !== '') {
                if (!Array.isArray(this.validaciones[index])) {
                  this.validaciones[index] = [];
                }
                this.validaciones[index].push(mensaje);
              }
            });
          }
          this.verCSV = true;
          this.validandoDocumento = true;
        })
        .catch((error) => {
          this.verError(error);
          this.validandoDocumento = true;
          return Promise.reject(error);
        });
    },
    /**
     * Verifica las validaciones de los registros procesados.
     * Si hay errores de validación, muestra la tabla de errores.
     * Si no hay errores, cierra la vista y emite un evento al componente padre.
     */
    verificarValidaciones() {
      if (this.validaciones.some(val => val.length > 0)) {
        this.registros = this.registrosTemporales.flat();
        this.registrosTemporales = [];
        this.verCSV = false;
        this.tabla = true;
        this.mostrarBotones = true;
        this.validandoDocumento = false;
        this.leyendoArchivo = false;
      } else {
        this.close();
        setTimeout(() => {
          this.$emit('mostrar-validacion', this.cantidadRegistros)
        }, 200);
      }
    },
    /**
     * Reinicia el estado del componente para volver a cargar un nuevo documento CSV.
     * Este método se encarga de reiniciar todas las variables de estado relacionadas con la
     * carga de documentos y las validaciones de manera que se pueda seleccionar y cargar
     * un nuevo archivo CSV para ser procesado nuevamente.
     */
    volverACargarDocumento() {
      this.validaciones = [];
      this.registros = [];
      this.validandoDocumento = false;
      this.formatoIncorrecto = false;
      this.mensajeIncorrecto = '';
      this.verCSV = true;
      this.mostrarBotones = false;
      this.tabla = false;
      this.cambioDeEstados = '';
      this.$refs.cargaDeRelaciones.value = '';
    },
    /**
     * Cierra el modal de carga de relaciones por medio de un evento emitido al padre y resetea los estados relacionados.
     */
    close() {
      this.validaciones = [];
      this.registros = [];
      this.validandoDocumento = false;
      this.formatoIncorrecto = false;
      this.mensajeIncorrecto = '';
      this.verCSV = true;
      this.mostrarBotones = false;
      this.tabla = false;
      this.leyendoArchivo = false;
      this.cambioDeEstados = '';
      this.rutaMasiva = null;
      this.estadoRuta = '';
      if (this.$refs.cargaDeRelaciones) {
        this.$refs.cargaDeRelaciones.value = '';
      }
      this.$emit('close-estado')
    },
    /**
     * Descarga el resultado de las validaciones como un archivo CSV.
     */
    descargarResultadoDeValidaciones() {
      const csvContent = this.generarCSV();
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'validaciones_afiliado_ruta.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    /**
     * Genera el contenido del archivo CSV a partir de los registros y sus validaciones.
     * @returns {string} - El contenido del archivo CSV.
     */
    generarCSV() {
      const eliminarTildes = (texto) => {
        return texto.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      };
      const limpiarMensaje = (mensaje) => {
        return mensaje.replace(/\n/g, ', ');
      };

      let csv = 'Ruta;Tipo documento;Documento;Validacion\n';

      // Descarga solo los registros con error en las validaciones 
      this.registros.forEach((registro, index) => {
        if (this.validaciones[index] && this.validaciones[index].length > 0) {
          const tipDoc = registro.tipDoc ? registro.tipDoc : '';
          const numDoc = registro.numDoc ? registro.numDoc : '';
          const validaciones = this.validaciones[index]
            .map(mensaje => limpiarMensaje(eliminarTildes(mensaje)))
            .join(', ');

          csv += `${tipDoc};${numDoc};${validaciones}\n`;
        }
      });
      return csv;
    },
    /**
     * Cierra la notificación
     */
    cerrarNotificacion() {
      this.mostrarNotificacion = false;
      this.mensajeNotificacion = "";
      this.pathNotificacion = "";
      this.tipoNotificacion = "";
    },
    /**
     * Muestra el error en una notificación correspondiente.
     * 
     * @param {*} error - Objeto de error.
     */
    verError(error) {
        
      let errorMessage = "Error desconocido, contacta con el administrador.";
      let errorPath = "";

      if (error.response && error.response.data) {
        if (error.response.data.mensaje && Array.isArray(error.response.data.mensaje)) {
          errorMessage = error.response.data.mensaje[0];
        } else if (error.response.data.error) {
          errorMessage = error.response.data.error;
        }
        if (error.response.data.path) {
          errorPath = error.response.data.path;
        }
      }
      this.pathNotificacion = errorPath;
      this.mensajeNotificacion = errorMessage;
      this.mostrarNotificacion = true;

      // Cierra la notificación después de 5 segundos
      setTimeout(() => {
        this.cerrarNotificacion();
      }, 5000);
    },
  },
}
</script>

<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
.title {
  background-color: #1867c0;
  color: white !important;
}

.archivo {
  outline: 2px dashed grey;
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 1rem;
  height: 100px;
  position: relative;
  cursor: pointer;
}

.archivo p {
  margin-top: .7rem;
  text-align: center;
}

.seleccionarArchivo {
  opacity: 0;
  width: 98%;
  height: 85px;
  position: absolute;
  cursor: pointer;
}

.seleccionarArchivo:disabled {
  cursor: default;
}

.rotate-animation {
  animation: rotate 2s linear infinite;
  cursor: default;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

::v-deep .tablaRelaciones div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}

.header tr {
  background-color: rgb(223, 223, 223) !important;
}

.header tr th {
  font-weight: bold;
  color: black !important;
}

.error-message {
  color: red;
  white-space: pre-line;
}

.notificacion {
  position: fixed;
  top: 50px;
  right: 20px;
  padding: 15px;
  border-radius: 5px;
  z-index: 9999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: opacity 0.5s ease-in-out;
  max-width: 30vw;
}

.notificacion span:last-child {
  cursor: pointer;
  margin-right: 10px;
  padding-top: 3px;
}

.rotate-animation-notification {
  animation: rotate-notification 1s ease-in-out; 
}

@keyframes rotate-notification  {
  0% {
    transform: rotateX(180deg);
  }

  50% {
    transform: rotateX(0deg);
  }

  100% {
    transform: rotateX(180deg);
  }
}

.size {
  font-size: xxx-large;
  font-weight: bold;
  color: #f80505;
}

.advertencia {
  background-color: #ffffffd7 !important;
  color: #f80505;
}

::v-deep .v-text-field .v-text-field__details {
  margin: 0;
}
</style>