<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Estefania Villada Chavarria                                   ###### -->
<!-- ###### @date: Octubre 2023                                                    ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
    <div class="contenido">
        <!-- Filtros para buscar pagadores -->
        <section class="d-flex">
            <!-- Filtrar por el nit del pagador -->
            <v-text-field class="me-2 filtro" v-model="filtro.nit" label="NIT" outlined dense hide-details
                @keypress="validarNumeros" maxlength="9">
            </v-text-field>
            <!-- Filtrar por el nombre del pagador -->
            <v-text-field class="me-2 filtro" v-model="filtro.nombre" label="Nombre" outlined dense hide-details
                @keypress="validarLetras">
            </v-text-field>
            <!-- Filtrar por codigo detalle cliente -->
            <v-text-field class="me-2 filtro" v-model="filtro.codigoDetalleCliente" label="Detalle cliente" outlined dense hide-details
                @keypress="validarNumeros">
            </v-text-field>
            <!-- Filtrar por estado -->
            <v-select class="me-2 filtro" v-model="filtro.estado" label="Estado" outlined dense hide-details
                :items="opcionEstados" :menu-props="{ offsetY: true }">
            </v-select>
            <!-- Botón que abre el formulario para crear un pagador -->
            <div>
                <v-tooltip left color="success" :open-on-focus="false">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn small fab color="success" v-bind="attrs" v-on="on" @mousedown.prevent="dialogoPagador = true">
                            <v-icon> add </v-icon>
                        </v-btn>
                    </template>
                    <span>Agregar</span>
                </v-tooltip>
            </div>
        </section>

        <!-- Diálogo para crear/editar un pagador -->
        <v-dialog v-model="dialogoPagador" transition="dialog-bottom-transition" max-width="35rem" persistent :editar="false">
            <v-card>
                <v-card-title class="encabezadoDialogo">
                    <span class="text-h6"> {{ tituloDialogo }} </span>
                </v-card-title>
                <v-card-text class="pt-5">
                    <validation-observer ref="observer" v-slot="{ invalid }">
                        <!-- Formulario para registrar un pagador -->
                        <v-form lazy-validation>
                            <div class="d-flex">
                                <!-- Campo para ingresar el nit -->
                                <validation-provider class="nit" v-slot="{ errors }" :rules="{ required: true }">
                                    <v-text-field v-model="crearPagador.nit" label="NIT" dense outlined :error-messages="errors"
                                        @keypress="validarNumeros" @input="listarCodigos()" :disabled="disabled" @paste.prevent maxlength="9">
                                    </v-text-field>
                                </validation-provider>
                                <!-- Tooltip -->
                                <div class="d-flex align-center tooltip">
                                    <v-tooltip right color="#1867c0">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon dark fab v-bind="attrs" v-on="on" color="#1867c0"> help </v-icon>
                                        </template>
                                        <span>Número de identificación tributaria.
                                            <br>Está compuesto por nueve dígitos.
                                            <br><em>Ejemplo: 890985122</em>
                                        </span>
                                    </v-tooltip>
                                </div>
                                <!-- Campo para ingresar el nombre del pagador -->
                                <validation-provider class="campo ms-2" v-slot="{ errors }" :rules="{ required: true }">
                                    <v-text-field v-model="crearPagador.nombrePagador" label="Nombre" dense outlined :error-messages="errors || mensajeNumeros"
                                        @keypress="validarLetras" @paste.prevent @input="validarSoloNumeros">
                                    </v-text-field>
                                </validation-provider>
                                <!-- Tooltip -->
                                <div class="d-flex align-center tooltip">
                                    <v-tooltip right color="#1867c0">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon dark fab v-bind="attrs" v-on="on" color="#1867c0"> help </v-icon>
                                        </template>
                                        <span>Nombre del pagador.</span>
                                    </v-tooltip>
                                </div>
                            </div>
                            <!-- Campo para ingresar el codigo detalle cliente -->
                            <div class="d-flex">
                                <validation-provider class="campo" v-slot="{ errors }" :rules="{ required: true }" ref="codigoValidation">
                                    <v-autocomplete v-model="crearPagador.detalleCliente" label="Detalle cliente" required dense outlined
                                        :error-messages="validarPagador || errors" :items="opciones.codigos" :menu-props="{ maxHeight: 200, offsetY: true, maxWidth: 485 }"
                                        @input="validarExistencia()" :disabled="disabled" no-data-text="No se encontraron resultados.">
                                    </v-autocomplete>
                                </validation-provider>
                                <!-- Tooltip -->
                                <div class="d-flex align-center tooltip">
                                    <v-tooltip right color="#1867c0">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon fab v-bind="attrs" v-on="on" dark color="#1867c0"> help </v-icon>
                                        </template>
                                        <span>El código detalle cliente es un número único
                                            <br> que identifica cada sucursal del cliente.
                                        </span>
                                    </v-tooltip>
                                </div>
                            </div>
                            <!-- Campo para ingresar el tipo de modalidad de pago -->
                            <div class="d-flex">
                                <validation-provider class="campo" v-slot="{ errors }" :rules="{ required: true }">
                                    <v-autocomplete v-model="crearPagador.modalidadPago" label="Modalidad de pago" dense outlined no-data-text="No se encontraron resultados."
                                        :error-messages="errors" :items="opciones.modalidadPago" :menu-props="{ maxHeight: 170, offsetY: true, maxWidth: 485 }" @change="verificarModalidadPago">
                                    </v-autocomplete>
                                </validation-provider>
                                <div class="d-flex align-center tooltip">
                                    <v-tooltip right color="#1867c0">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon fab v-bind="attrs" v-on="on" dark color="#1867c0"> help </v-icon>
                                        </template>
                                        <span>Hace referencia a la modalidad de pago
                                            <br>acordado entre las partes.</span>
                                    </v-tooltip>
                                </div>
                            </div>
                            <!-- Campo para ingresar el tipo de cobertura -->
                            <div class="d-flex">
                                <validation-provider class="campo" v-slot="{ errors }" :rules="{ required: true }">
                                    <v-autocomplete v-model="crearPagador.coberturaPlan" label="Cobertura plan" dense outlined no-data-text="No se encontraron resultados."
                                        :error-messages="errors" :items="opciones.coberturaPlan" :menu-props="{ maxHeight: 170, offsetY: true, maxWidth: 485 }" @change="verificarCoberturaPlan">
                                    </v-autocomplete>
                                </validation-provider>
                                <div class="d-flex align-center tooltip">
                                    <v-tooltip right color="#1867c0">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon fab v-bind="attrs" v-on="on" dark color="#1867c0"> help </v-icon>
                                        </template>
                                        <span>Hace referencia al tipo de entidad responsable
                                            <br>de financiar la cobertura o plan de beneficios,
                                            <br>y pagar la prestación de los servicios.</span>
                                    </v-tooltip>
                                </div>
                            </div>

                            <div v-if="mensaje.length">
                                <p class="error--text text-caption ma-0" v-for="(msg, index) in mensaje" :key="index">{{ msg }}</p>
                            </div>

                            <!-- Botones del formulario -->
                            <div class="d-flex justify-end">
                                <v-btn class="me-2" text color="error" @click="clear()">Cerrar</v-btn>
                                <v-btn color="success" :disabled="invalid || botonValidacion || !formValid" text depressed
                                @click="!editar ? agregarPagador() : editarPagador()">Guardar</v-btn>
                            </div>
                        </v-form>
                    </validation-observer>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- Tabla que almacena la información de los pagadores -->
        <v-data-table class="tabla mt-4" :items="tablaPagador.items" :loading="tablaPagador.loading"
            fixed-header :headers="tablaPagador.headers" :footer-props="tablaPagador.footerProps"
            :items-per-page.sync="tablaPagador.itemsPerPage" :page.sync="tablaPagador.page" :server-items-length="tablaPagador.totalPage"
            height="64vh">
            <template v-slot:body="{ items }">
                <tbody>
                    <tr v-for="item in items" v-bind:key="item.idPagador">
                        <td>{{ item.nit }}</td>
                        <td>{{ item.nombrePagador }}</td>
                        <td class="text-center">{{ item.detalleCliente }}</td>
                        <td class="text-center">{{ item.modalidadPago.codigoModalidad }}</td>
                        <td class="text-center">{{ item.coberturaPlan.codigoCobertura }}</td>
                        <td>
                            <v-icon :color="item.eliminado === false ? 'success' : 'error'">
                                {{ item.eliminado === false ? 'check_circle' : 'cancel' }}
                            </v-icon>
                            {{ item.eliminado === false ? 'Activo' : 'Inactivo' }}
                        </td>
                        <td class="text-center">
                            <v-btn icon medium @click="abrirDialogoEditar(item)">
                                <v-icon color="orange"> border_color </v-icon>
                            </v-btn>
                            <v-btn icon @click="abrirDialogoEstado(item)">
                                <v-icon :color="item.eliminado === false ? 'error' : 'success'">
                                    {{ item.eliminado === false ? 'person_add_disabled' : 'how_to_reg' }}
                                </v-icon>
                            </v-btn>
                        </td>
                        <td class="text-center">
                            <v-btn icon medium @click="verTablaTrazabilidad(item)">
                                <v-icon color="blue"> backup_table </v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
            </template>
            <template v-slot:footer.page-text="items">
                {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
            </template>
        </v-data-table>

        <!-- Diálogo para cambiar el estado de un pagador -->
        <v-dialog v-model="dialogoEstado" transition="dialog-bottom-transition" max-width="25rem" persistent>
            <v-card>
                <v-card-title class="encabezadoDialogo">
                    <span class="text-h6"> {{ mensajeEstado }} </span>
                </v-card-title>
                <v-card-text class="pt-5">
                    <div>
                        <p v-if="cargando" class="text-center ma-0">
                            Por favor espere un momento...
                            <v-icon :class="{ 'rotate-animation': cargando }"
                                large>rotate_right
                            </v-icon>
                        </p>
                    </div>
                    <div class="d-flex justify-end">
                        <v-btn v-if="!cargando" class="me-2" color="error" text @click="dialogoEstado = false">No</v-btn>
                        <v-btn v-if="!cargando" color="success" depressed text @click="cambiarEstado()">Si</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- Diálogo para la trazabilidad de un pagador -->
        <v-dialog v-model="dialogoTrazabilidad" persistent transition="dialog-bottom-transition" max-width="60rem">
            <v-card>
                <v-card-title class="encabezadoDialogo">
                    <span class="text-h6"> Trazabilidad: {{ pagadorSeleccionado.nombrePagador }}</span>
                </v-card-title>
                <v-card-text class="pt-5">
                    <v-data-table class="tabla traza" :items="tablaTrazabilidad.items"
                        :headers="tablaTrazabilidad.headers" :footer-props="tablaTrazabilidad.footerProps"
                        :items-per-page="tablaTrazabilidad.itemsPerPage" :page.sync="tablaTrazabilidad.page"
                        :sort-by="'time'" :sort-desc="true" height="45vh" fixed-header>
                        <template v-slot:body="{ items }">
                            <tbody>
                                <tr v-for="item in items" v-bind:key="item.rev">
                                    <td> {{ nombresCampos[item.campo.toLowerCase()] }} </td>
                                    <td>
                                        <template v-if="item.campo === 'eliminado'">
                                            <span v-if="item.anterior === 'false'">Activo</span>
                                            <span v-if="item.anterior === 'true'">Inactivo</span>
                                        </template>
                                        <template v-if="validarCampos(item)">
                                            {{ item.anterior }}
                                        </template>
                                    </td>
                                    <td>
                                        <template v-if="item.campo === 'eliminado'">
                                            <span v-if="item.nuevo == 'false'">Activo</span>
                                            <span v-if="item.nuevo == 'true'">Inactivo</span>
                                        </template>
                                        <template v-if="validarCampos(item)">
                                            {{ item.nuevo }}
                                        </template>
                                    </td>
                                    <td class="text-center">
                                        {{ formatoFecha(item.time) }}
                                    </td>
                                    <td class="text-center">
                                        {{ item.username }}
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                        <template v-slot:footer.page-text="items">
                            {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
                        </template>
                    </v-data-table>
                    <div class="d-flex justify-end mt-2">
                        <v-btn color="error" text @click="dialogoTrazabilidad = false">Cerrar</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

    </div>
</template>
<!-- #################################################################################### -->
<!-- ###### Sección de Script                                                      ###### -->
<!-- #################################################################################### -->
<script>
import { mapMutations, mapState } from "vuex";
import { required } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate';

setInteractionMode('eager')
extend('required', {
    ...required,
    message: 'Este campo es requerido.',
});

export default {
    name: "Pagador",
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            filtro: {
                nit: '',
                nombre: '',
                codigoDetalleCliente: '',
                estado: ''
            },
            opcionEstados: [
                { text: 'Todos', value: '' },
                { text: 'Activo', value: false },
                { text: 'Inactivo', value: true },
            ],
            dialogoPagador: false,
            tituloDialogo: 'Crear pagador',
            crearPagador: {
                nit: '',
                nombrePagador: '',
                detalleCliente: '',
                modalidadPago: '',
                coberturaPlan: ''
            },
            opciones: {
                modalidadPago: [],
                coberturaPlan: [],
                codigos: []
            },
            tablaPagador: {
                items: [],
                loading: false,
                headers: [
                    { text: "NIT", align: "left", width: "10%", sortable: false },
                    { text: "NOMBRE", align: "left", width: "25%", sortable: false },
                    { text: "DETALLE CLIENTE", align: "center", width: "10%", sortable: false },
                    { text: "MODALIDAD PAGO", align: "center", width: "10%", sortable: false },
                    { text: "COBERTURA PLAN", align: "center", width: "10%", sortable: false },
                    { text: "ESTADO", align: "left", width: "13%", sortable: false },
                    { text: "ACCIONES", align: "center", width: "12%", sortable: false },
                    { text: "TRAZABILIDAD", align: "center", width: "10%", sortable: false },
                ],
                footerProps: {
                    'items-per-page-options': [10, 15, 20, 30],
                    'items-per-page-text': 'Items por página:',
                    'show-current-page': true,
                    'show-first-last-page': true
                },
                itemsPerPage: 10,
                page: 1,
                totalPage: 0,
            },
            dialogoTrazabilidad: false,
            tablaTrazabilidad: {
                items: [],
                headers: [
                    { text: "CAMPO", align: "left", width: "20%", sortable: false },
                    { text: "VALOR ANTERIOR", align: "left", width: "20%", sortable: false },
                    { text: "VALOR ACTUAL", align: "left", width: "20%", sortable: false },
                    { text: "FECHA DE MODIFICACIÓN", align: "center", width: "25%", sortable: false },
                    { text: "USUARIO", align: "center", width: "15%", sortable: false },
                ],
                footerProps: {
                    'show-current-page': true,
                    'show-first-last-page': true,
                },
                itemsPerPage: 5,
                page: 1,
            },
            nombresCampos: {
                nombre_pagador: 'Nombre',
                detalle_cliente: 'Detalle cliente',
                eliminado: 'Estado',
                nit: 'NIT',
                codigo_cobertura: 'Cobertura plan',
                codigo_modalidad: 'Modalidad pago'
            },
            codigoEmpresa: 1,
            lastmodifiedby: '',
            dialogoEstado: false,
            mensajeEstado: '',
            pagadorSeleccionado: [],
            botonValidacion: false,
            validarPagador: '',
            editar: false,
            disabled: false, 
            cargando: false,
            validacion: false,
            mensaje: [],
            mensajeNumeros: '',
        }
    },
    computed: {
        ...mapState(["auth", "notify", "busy", "enterprise"]),
        formValid() {
            return this.crearPagador.nit && this.crearPagador.nombrePagador && this.crearPagador.detalleCliente 
            && this.crearPagador.coberturaPlan && this.crearPagador.modalidadPago && !this.validarPagador && !this.validacion
            && !this.mensajeNumeros;
        }
    },
    watch: {
        'tablaPagador.page': function () {
            this.listarPagadores();
        },
        'tablaPagador.itemsPerPage': function () {
            this.tablaPagador.page = 1;
            this.listarPagadores();
        },
        'filtro.nit': function () {
            this.tablaPagador.page = 1;
            this.listarPagadores();
        },
        'filtro.nombre': function () {
            this.tablaPagador.page = 1;
            this.listarPagadores();
        },
        'filtro.codigoDetalleCliente': function () {
            this.tablaPagador.page = 1;
            this.listarPagadores();
        },
        'filtro.estado': function () {
            this.tablaPagador.page = 1;
            this.listarPagadores();
        },
        'tablaTrazabilidad.items': function () {
            this.tablaTrazabilidad.page = 1;
        },
        'dialogoPagador': function () {
            if (this.dialogoPagador) {
                this.listarCobertura();
                this.listarModalidades();
            }
        },
        'dialogoEstado': function (nextValue) {
            if (nextValue === false) {
                setTimeout(() => {
                    this.cargando = false;
                }, 500)
            }
        },
        'mensaje' : function () {
            if (this.mensaje.length === 0) {
                this.validacion = false;
            }
        }
    },
    methods: {
        ...mapMutations([
        "updateAuth",
        "hideNotify",
        "showSuccess",
        "showBusy",
        "hideBusy",
        "showError"
        ]),
        /**
         * Esta función se utiliza para validar que solo se ingresen números en un campo de entrada de texto
         * si la tecla presionada no corresponde a números, se evita que se ingrese el caracter
         * @param {*} event - El evento del teclado que se produce al escribir en el campo de entrada
         */
        validarNumeros(event) {
            const key = event.key;
            if (key.match(/\D/)) {
                event.preventDefault();
            }
        },
        /**
         * Esta función se utiliza para validar que solo se ingresen letras(con y sin tíldes), espacios, puntos y guiones en un campo de entrada de texto
         * si la tecla presionada no corresponde a la condición, se evita que se ingrese el caracter
         * @param {evento} event - El evento del teclado que se produce al escribir en el campo de entrada
         */
        validarLetras(event) {
            const key = event.key;
            if (!key.match(/[a-zA-ZáéíóúÁÉÍÓÚ .-]/)) {
                event.preventDefault();
            }
        },
        /**
         * Método que valida si se ingresan números
         */
        validarSoloNumeros() {
            const valor = this.crearPagador.nombrePagador;
            if (/\d/.test(valor)) {
                this.mensajeNumeros = 'Este campo no permite números.';
            } else {
                this.mensajeNumeros = '';
            }
        },
        /**
         * Lista los pagadores en una tabla mediante una solicitud HTTP GET con los siguientes parámetros:
         * page: página actual
         * size: tamaño de la página o número de elementos por página
         * nit: Filtrar por el NIT del pagador
         * nombre: Filtrar por el nombre del pagador
         * codigoDetalleCliente: Filtrar por el código detalle cliente del pagador
         * estado: Filtrar por el estado de un pagador, puede ser activo o inactivo
         * codigoEmpresa: Filtrar por el código empresa al que pertence el usuario
         */
        listarPagadores() {
            this.tablaPagador.loading = true;
            this.$http.get(`msa-administration/pagador/listarPagadores`, {
                params: {
                    page: this.tablaPagador.page -1,
                    size: this.tablaPagador.itemsPerPage,
                    nit: `${this.filtro.nit}`,
                    nombre: `${this.filtro.nombre.toLowerCase()}`,
                    codigoDetalleCliente: `${this.filtro.codigoDetalleCliente}`,
                    estado: `${this.filtro.estado}`,
                    codigoEmpresa: this.enterprise.code
                }
            }).then((response) => {
                this.tablaPagador.items = response.data.content;
                this.tablaPagador.totalPage = response.data.totalElements;
                this.tablaPagador.loading = false;
            }).catch((error) => {
                console.log(error);
                this.tablaPagador.loading = false;
                this.showError(`Error al listar los pagadores: ${error}`);
            })
        },
        /**
         * Lista los códigos detalle cliente dependiendo del NIT ingresado mediante una solicitud
         * HTTP GET con los siguientes parámetros:
         * codigoEmpresa: Codigo de la empresa en la que está relacionado el NIT
         * codigoCliente: NIT del pagador a crear
         */
        async listarCodigos() {
            this.opciones.codigos = [];
            if (this.crearPagador.nit.length === 9) {
                try {
                    const response = await this.$http.get(`msa-administration/caDecli/listarCodigosClientes`, {
                        params: {
                            codEmpr: this.enterprise.code,
                            nit: this.crearPagador.nit
                        }
                    });
                    if (this.editar === false) {
                        this.opciones.codigos = response.data.filter(item => item.codApro === 10).map(item => ({
                            text: `${item.codDecl} - ${item.nomDecl}`,
                            value: item.codDecl
                        }))
                    } else {
                        this.opciones.codigos = response.data.map(item => ({
                            text: `${item.codDecl} - ${item.nomDecl}`,
                            value: item.codDecl
                        }))

                        const codigoInactivo = response.data.find(item => {
                            return item.codDecl.toString() === this.crearPagador.detalleCliente.toString() && item.codApro !== 10
                        });

                        if (codigoInactivo) {
                            this.validacion = true;
                            this.mensaje.push('El detalle cliente está inactivo. Contacte con el área de cartera.')
                        }
                    }
                } catch (error) {
                    console.log(error);
                    this.showError(`Error al listar los detalle cliente: ${error}`);
                }
                this.crearPagador.detalleCliente = '';
            }
        },
        /**
         * Realiza una solicitud HTTP GET para obtener y listar las modalidades de pago disponibles.
         */
        listarModalidades() {
            this.$http.get(`msa-administration/modalidadPago/listarModalidadPago`)
            .then((response) => {
                if (this.editar === false) {
                    this.opciones.modalidadPago = response.data.filter(item => item.eliminado === false).map(item => ({
                        text: `${item.codigoModalidad} - ${item.descripcionModalidad}`,
                        value: item.codigoModalidad
                    }))
                } else {
                    this.opciones.modalidadPago = response.data.map(item => ({
                        text: `${item.codigoModalidad} - ${item.descripcionModalidad}`,
                        value: item.codigoModalidad
                    }))

                    const codigoInactivo = response.data.find(item => {
                        return item.codigoModalidad.toString() === this.crearPagador.modalidadPago.toString() && item.eliminado === true
                    });

                    if (codigoInactivo) {
                        this.validacion = true;
                        this.mensaje.push('La modalidad de pago está inactiva. Contacte con soporte.')
                    }
                }
            }).catch((error) => {
                console.log(error);
                this.showError(`Error al listar las modalidades de pago: ${error}`);
            })
        },
        /**
         * Realiza una solicitud HTTP GET para obtener y listar las coberturas de planes disponibles.
         */
        listarCobertura() {
            this.$http.get(`msa-administration/coberturaPlan/listarCobertura`)
            .then((response) => {
                if (this.editar === false) {
                    this.opciones.coberturaPlan = response.data.filter(item => item.eliminado === false).map(item => ({
                        text: `${item.codigoCobertura} - ${item.descripcionCobertura}`,
                        value: item.codigoCobertura 
                    }))
                } else {
                    this.opciones.coberturaPlan = response.data.map(item => ({
                        text: `${item.codigoCobertura} - ${item.descripcionCobertura}`,
                        value: item.codigoCobertura
                    }))

                    const codigoInactivo = response.data.find(item => {
                        return item.codigoCobertura.toString() === this.crearPagador.coberturaPlan.toString() && item.eliminado === true
                    });

                    if (codigoInactivo) {
                        this.validacion = true;
                        this.mensaje.push('La cobertura plan está inactiva. Contacte con soporte.')
                    } 
                }
            }).catch((error) => {
                console.log(error);
                this.showError(`Error al listar las coberturas de plan: ${error}`);
            })
        },
        /**
         * Valida el estado del codigo de la modalidad de pago que se seleccionó y dependiendo de la respuesta saca el mensaje de la validación
         */
        async verificarModalidadPago() {
            try {
                const response = await this.$http.get(`msa-administration/modalidadPago/listarModalidadPago`);
                const modalidades = response.data;
                
                const modalidadSeleccionada = modalidades.find(modalidad => modalidad.codigoModalidad === this.crearPagador.modalidadPago);

                if (modalidadSeleccionada) {
                    if (modalidadSeleccionada.eliminado === true) {
                        this.validacion = true;
                        if (!this.mensaje.includes('La modalidad de pago está inactiva. Contacte con soporte.')) {
                            this.mensaje.push('La modalidad de pago está inactiva. Contacte con soporte.');
                        }
                    } else {
                        this.mensaje = this.mensaje.filter(msg => !msg.includes('modalidad de pago'));
                    }
                } else {
                    console.error("Modalidad de pago seleccionada no encontrada.");
                }
            } catch (error) {
                console.log(error);
                this.showError(`Error al verificar la modalidad de pago: ${error}`);
                this.botonValidacion = true;
            }
        },
       /**
         * Valida el estado del codigo de la cobertura plan que se seleccionó y dependiendo de la respuesta saca el mensaje de la validación
         */
        async verificarCoberturaPlan() {
            try {
                const response = await this.$http.get(`msa-administration/coberturaPlan/listarCobertura`);
                const coberturas = response.data;

                const coberturaSeleccionada = coberturas.find(cobertura => cobertura.codigoCobertura === this.crearPagador.coberturaPlan);

                if (coberturaSeleccionada && coberturaSeleccionada.eliminado === false) {
                    this.mensaje = this.mensaje.filter(msg => !msg.includes('cobertura plan'));
                } else if (coberturaSeleccionada && coberturaSeleccionada.eliminado === true) {
                    this.validacion = true;
                    if (!this.mensaje.includes('La cobertura plan está inactiva. Contacte con soporte.')) {
                        this.mensaje.push('La cobertura plan está inactiva. Contacte con soporte.');
                    }
                } else {
                    console.error("Cobertura plan seleccionada no encontrada.");
                }
            } catch (error) {
                console.log(error);
                this.showError(`Error al verificar la cobertura plan: ${error}`);
                this.botonValidacion = true;
            }
        },
        /**
         * Valida la existencia de un pagador por medio de una solicitud HTTP GET con los siguientes parámetros de coincidencia:
         * nit: El NIT del pagador que se está queriendo ingresar
         * detalleCliente: El código detalle cliente que se está queriendo ingresar
         * codigoEmpresa: El identificador asociado a la empresa
         * Si existe desactiva el botón de guardar y saca un mensaje de error, de lo contrario permite guardar el pagador
         */
        validarExistencia() {
            if (this.crearPagador.nit && this.crearPagador.detalleCliente) {
                this.$http
                    .get(`msa-administration/pagador/validarExistencia`, {
                        params: {
                            nit: this.crearPagador.nit,
                            detalleCliente: this.crearPagador.detalleCliente,
                            idEmpresa: this.enterprise.code
                        }
                    })
                    .then((response) => {
                        if (response.data !== null) {
                            this.validarPagador = "El pagador ya existe.";
                            this.botonValidacion = true;
                        } else {
                            this.validarPagador = "";
                            this.botonValidacion = false;
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.showError(`Error al validar la existencia del pagador: ${error}`);
                        this.botonValidacion = true;
                    })
            }
        },
        /**
         * Agrega un nuevo pagador enviando una solicitud HTTP POST con los datos proporcionados.
         * nit: El NIT del pagador
         * nombrePagador: El nombre del pagador
         * detalleCliente: El código detalle cliente del pagador
         * estado: El estado del registro
         * codigoEmpresa: El código de empresa relacionado al usuario
         * lastmodifiedby: El último usuario que modificó el registro
         * coberturaPlan:
         * modalidadPlan:
         */
        agregarPagador() {
            this.botonValidacion = true;
            const pagador = {
                nit: this.crearPagador.nit,
                nombrePagador: this.crearPagador.nombrePagador,
                detalleCliente: this.crearPagador.detalleCliente,
                eliminado: false,
                empresa: {
                    idEmpresa: this.enterprise.code
                },
                lastmodifiedby: this.lastmodifiedby,
                coberturaPlan: {
                    codigoCobertura: this.crearPagador.coberturaPlan
                },
                modalidadPago: {
                    codigoModalidad: this.crearPagador.modalidadPago
                }
            }
            this.$http.post(`msa-administration/pagador/guardar`, pagador)
            .then(() => {
                this.listarPagadores();
                this.dialogoPagador = false;
                this.clear();
                this.showSuccess("Pagador creado correctamente");
            }).catch((error) => {
                console.log(error);
                this.showError(`Error al guardar el pagador: ${error}`);
            })
        },
        /**
         * Abre un diálogo para cambiar el estado de un pagador (activar o inactivar) y configura el mensaje correspondiente.
         * @param {*} item - El objeto de pagador que se seleccionará para cambiar su estado.
         */
        abrirDialogoEstado(item) {
            this.dialogoEstado = true;
            this.pagadorSeleccionado = item;
            this.mensajeEstado = this.pagadorSeleccionado.eliminado === false ? '¿Desea inactivar el pagador?' : '¿Desea activar el pagador?';
        },
        /**
         * Cambia el estado de un pagador entre false (activo) y true (inactivo) por medio de una solicitud HTTP PUT y
         * actualiza este cambio en el servidor. Luego, se lista de nuevo los pagadores y se cierra el diálogo de cambio de estado.
         */
        cambiarEstado() {
            this.cargando = true;
            this.pagadorSeleccionado.eliminado = (this.pagadorSeleccionado.eliminado == false) ? true : false;
            const estado = {
                idPagador: this.pagadorSeleccionado.idPagador,
                eliminado: this.pagadorSeleccionado.eliminado,
                lastmodifiedby: this.lastmodifiedby
            }
            this.$http.put(`msa-administration/pagador/actualizarEstado`, estado)
            .then(() => {
                this.listarPagadores();
                this.dialogoEstado = false;
                this.showSuccess("Estado actualizado correctamente");
            }).catch((error) => {
                console.log(error);
                this.showError(`Error al actualizar el estado: ${error}`);
            })
        },
        /**
         * Abre el diálogo de editar pagador con los datos del objeto seleccionado(datos actuales del pagador)
         * y la configuración necesaria para editar el pagador.
         * @param {*} item - Objeto que contiene los datos de la aseguradora a editar.
         */
        async abrirDialogoEditar(item) {
            this.editar = true;
            this.crearPagador = { ...item }
            this.crearPagador.coberturaPlan = item.coberturaPlan.codigoCobertura;
            this.crearPagador.modalidadPago = item.modalidadPago.codigoModalidad;
            await this.listarCodigos();
            this.crearPagador.detalleCliente = this.opciones.codigos.find(detalle => detalle.value === item.detalleCliente);
            this.disabled = true;
            this.tituloDialogo = 'Editar pagador';
            this.pagadorSeleccionado = item;
            this.dialogoPagador = true;
            this.$nextTick(() => { 
                this.validarSoloNumeros();
            }, 500);
        },
        /**
         * Actualiza la información del pagador seleccionado mediante una petición put,
         * finalmente se actualiza la tabla de datos y cierra el diálogo.
         */
        editarPagador() {
            const pagador = {
                idPagador: this.pagadorSeleccionado.idPagador,
                nit: this.crearPagador.nit,
                nombrePagador: this.crearPagador.nombrePagador,
                detalleCliente: this.pagadorSeleccionado.detalleCliente,
                coberturaPlan: {
                    codigoCobertura: this.crearPagador.coberturaPlan
                },
                modalidadPago: {
                    codigoModalidad: this.crearPagador.modalidadPago
                },
                eliminado: false,
                lastmodifiedby: this.lastmodifiedby
            };
            this.$http.put(`msa-administration/pagador/actualizar`, pagador)
            .then(() => {
                this.listarPagadores();
                this.dialogoPagador = false;
                this.showSuccess("Pagador actualizado correctamente");
                setTimeout(() => {
                    this.clear();
                }, 1000);
            }).catch((error) => {
                console.log(error);
                this.showError(`Error al actualizar el pagador: ${error}`);
            })
        },
        /**
         * Muestra tabla de trazabilidad de un pagador determinado mediante una petición get que obtiene los datos de la trazabilidad
         * a través del parámetro id que es la variable "pagadorSeleccionado.idPagador"
         * @param {Object} item - El objeto del pagador seleccionado(los datos datos actuales del pagador) para el cual se mostrará
         * la trazabilidad.
         */
        verTablaTrazabilidad(item) {
            this.dialogoTrazabilidad = true;
            this.pagadorSeleccionado = item;
            this.$http
                .get(`msa-administration/pagador/listarTraza`, {
                    params: {
                        id: this.pagadorSeleccionado.idPagador,
                    }
                }).then(response => {
                    this.tablaTrazabilidad.items = response.data;
                }).catch((error) => {
                    console.log(error);
                    this.showError(`Error al buscar la trazabilidad: ${error}`);
                })
        },
        /**
         * Valida si el campo de un objeto debe ser excluido en la tabla de trazabilidad
         * @param {Object} item - El objeto que contiene el campo a validar.
         * @param {string} item.campo - El nombre del campo a validar.
         */
        validarCampos(item) {
            const excluir = ['nombre_pagador', 'detalle_cliente', 'nit', 'codigo_cobertura', 'codigo_modalidad']
            return excluir.includes(item.campo)
        },
        /**
         * Formatea una cadena de fecha y hora en un formato "YYYY/MM/DD HH:MM a.m./p.m."
         * @param {string} dateTimeString - La cadena de fecha y hora en formato ISO 8601.
         */
        formatoFecha(dateTimeString) {
            const fecha = new Date(dateTimeString);
            const horas = fecha.getHours();
            const minutos = fecha.getMinutes();
            const ampm = horas >= 12 ? 'p.m.' : 'a.m.';
            const formatoHoras = horas % 12 || 12;
            const formatoMinutos = minutos < 10 ? `0${minutos}` : minutos;
            const formatoMes = (fecha.getMonth() + 1).toString().padStart(2, '0');
            const formatoDia = fecha.getDate().toString().padStart(2, '0');
            const formatoFechaHora = `${fecha.getFullYear()}/${formatoMes}/${formatoDia} ${formatoHoras.toString().padStart(2, '0')}:${formatoMinutos} ${ampm}`;
            return formatoFechaHora;
        },
        /**
         * Reestablece varias variables y propiedades a sus valores predeterminados o vacíos, también reestablece los campos de entrada de un
         * formulario a su estado inicial.
         * Se utiliza para limpiar y preparar el estado del programa antes de realizar una nueva operación.
         */
        clear() {
            this.$refs.observer.reset();
            this.dialogoPagador = false;
            this.crearPagador.nit = '';
            this.crearPagador.nombrePagador = '';
            this.crearPagador.detalleCliente = '';
            this.crearPagador.modalidadPago = '';
            this.crearPagador.coberturaPlan = '';
            this.botonValidacion = false;
            this.validarPagador = '';
            this.opciones.codigos = [];
            this.editar = false;
            setTimeout(() => {
                this.tituloDialogo = 'Crear pagador';
                this.validacion = false;
                this.mensaje = [];
                this.mensajeNumeros = '';
            }, 500);
            this.disabled = false;
        },
    },
    mounted() {
        this.lastmodifiedby = this.auth.username.toUpperCase();
        this.listarPagadores();
    }
}
</script>
<!-- #################################################################################### -->
<!-- ###### Sección de Style                                                       ###### -->
<!-- #################################################################################### -->
<style scoped>
.contenido {
    padding: 1rem;
    width: 100%;
    height: 86vh;
}
.filtro {
    width: 25%;
}
.encabezadoDialogo{
    background-color: #1867c0;
    color: white;
}
.campo {
    width: 100% !important;
}
.nit {
    width: 18rem;
}
.tooltip {
    height: 2.5rem;
    margin-left: 3px;
}
::v-deep .tabla div table thead tr th {
    background-color: rgb(223, 223, 223) !important;
}
::v-deep .tabla div table thead tr th span {
    font-weight: bold;
    color: black !important;
}
::v-deep .tabla .v-data-footer {
    width: 100%;
}
::v-deep .traza .v-data-footer__select {
    display: none;
}
::v-deep .traza .v-data-footer {
    padding-top: 10px;
    justify-content: end;
}
.rotate-animation {
    animation: rotate 2s linear infinite;
}
@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
</style>