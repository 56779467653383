<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Jaime Alejandro Zapata Velásquez                              ###### -->
<!-- ###### @date: Junio 2024                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
    <v-container fluid v-show="mostrarContenido" style="height: 81vh; margin-top: 20px">
            <v-layout> 
                <v-container fluid>
                    <v-card class="pa-4" style="max-height: 100vh; overflow-y: auto; overflow-x: hidden;">             
                        <div><h3 style="margin-left: 1.2rem;">Cargar RIPS según resolución número 3374 de 2000</h3></div>
                        <div style="margin-left: 1.2rem;">
                            • Esta funcionalidad no es un validador en cuanto a la calidad de los datos, 
                            valída la estructura de los RIPS en cuanto a cantidad de columnas y caractéres.<br> 
                            • La calidad del dato debe ser validada en el momento de la generación del archivo.<br>
                            • La carga de los documentos solamente se hace cuando todos los archivos han sido 
                            validados en su totalidad de columnas requeridas y la cantidad de caracteres que debe contener cada dato</div>
                        <v-row style="padding: 30px">                                     
                            <v-form :disabled="activeForm" class="full-width" v-model="formValidarCargarRips">
                                <div v-if="!activeForm" style="">
                                    
                                    <v-row>

                                            <!-- input 1 -->
                                            <v-col cols="12" md="3" v-if="userRoles.includes('MallaValidadora_SolicitudServicio_FormularioSolicitud_Interno')">
                                                <v-autocomplete style="width: 95%;" class="me-5" v-model="prestador"
                                                    :items="listaIps" dense outlined return-object :item-text="mostrarListaIps" label="Prestador">
                                                </v-autocomplete>
                                            </v-col>


                                        <!-- input 2 -->
                                        <v-col cols="12" md="3">
                                            <v-menu ref="menuPeriodoCarga" v-model="menuPeriodoCargaVisible" :close-on-content-click="false"
                                                    transition="scale-transition" offset-y>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <validation-provider :rules="{ required: true }">
                                                        <v-text-field 
                                                            v-model="periodoCarga"
                                                            label="Fecha de Carga *"
                                                            readonly 
                                                            v-bind="attrs" 
                                                            v-on="on" 
                                                            dense
                                                            outlined
                                                            class="full-width">
                                                        </v-text-field>
                                                    </validation-provider>
                                                </template>
                                                <v-date-picker 
                                                    v-model="periodoCarga" 
                                                    :active-picker.sync="activePicker"
                                                    :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                                                    min="1950-01-01" 
                                                    locale="es-co" 
                                                    :first-day-of-week="1"
                                                    @change="savePeriodoCargaVisible">
                                                </v-date-picker>
                                            </v-menu>
                                        </v-col>

                                        <!-- input 3 -->
                                        <v-col cols="12" md="3">
                                            <!-- input 2 -->
                                            <v-file-input ref="refRipsDocument" outlined dense prepend-icon="" label="Seleccionar archivo de control (CT)">
                                                <template v-slot:selection="{ text }">
                                                    <v-chip small label color="primary"> {{ text }} </v-chip>
                                                </template>
                                            </v-file-input>
                                        </v-col>

                                        <!-- input 4 -->
                                        <v-col cols="12" md="3">
                                        <v-row class="justify-start align-center" style="margin: 0; padding: 0; margin-left: 17px">
                                            <v-btn color="primary" @click="triggerFileInput">Seleccionar Carpeta</v-btn>
                                            <input
                                            type="file"
                                            webkitdirectory
                                            directory
                                            multiple
                                            @change="handleDirectoryUpload"
                                            ref="fileInput"
                                            style="display: none;"
                                            prefix="Seleccionar archivo" prepend-icon :clearable="true" :truncate-length="sizeText"
                                            />
                                        </v-row>
                                        </v-col>
                                    </v-row>
                                
                                    <template>
                                        <div class="d-flex">            
                                            <!-- Inputs de archivo dinámicos -->
                                            <div class="file-input-container" style="width: 100%; margin-top: 20px">
                                            <div v-for="(file, index) in selectedRipFiles" :key="index" class="file-input-row" style="margin-top: -20px">
                                                <v-row>
                                                <v-col cols="8" md="6" class="text-label">
                                                    <label :for="'file-' + index" style="width: 100%;" class="file-input-label">{{ file.text }}:</label>
                                                </v-col>
                                                <v-col cols="10" md="4">
                                                    <v-file-input
                                                    style="width: 100%;" 
                                                    v-model="file.uploadedFile"
                                                    :label="`Cargar archivo para ${file.text}`"
                                                    outlined
                                                    dense
                                                    show-size
                                                    :id="'file-' + index"
                                                    prepend-icon=""
                                                    />
                                                </v-col>
                                                <v-col cols="2" md="2" class="text-center d-flex align-center pa-0" syle="margin-top: -26px;">
                                                    <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn icon v-bind="attrs" v-on="on" @click="abrirDocumento(file.text)" class="icon-btn">
                                                            <v-icon medium color="#0d47a1">visibility</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Ver documento</span>
                                                    </v-tooltip>
                                                    <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn icon v-bind="attrs" v-on="on" @click="borrarDocumentoIcono(file.text)" class="icon-btn">
                                                            <v-icon medium color="error">delete</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Borrar documento</span>
                                                    </v-tooltip>
                                                </v-col>
                                                </v-row>
                                            </div>
                                            </div>
                                        </div>
                                    </template>

                                    <template>
                                        <v-dialog ref="dialogDocumentos" v-model="mostrarModal" max-width="40rem" scrollable persistent>
                                            <v-card class="documentos">
                                            <v-card-title>{{ modalTitulo }}</v-card-title>
                                            <v-card-text>
                                                <!-- Mostrar archivo de texto -->
                                                <template v-if="componenteDocumento === 'txt'">
                                                <pre>{{ documentoSeleccionado }}</pre>
                                                </template>
                                                <!-- Mensaje para tipos no compatibles -->
                                                <template v-else>
                                                <p>Formato de archivo no compatible o no seleccionado.</p>
                                                </template>
                                            </v-card-text>
                                            <v-card-actions class="justify-end">
                                                <v-btn v-if="!visibility" class="boton-actualizar mb-4" @click="cerrarModal">Cancelar</v-btn>
                                                <v-btn v-if="visibility" class="boton-actualizar mb-2 me-2" @click="mostrarModal = false">Cerrar</v-btn>
                                            </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                    </template>
                                </div>                                                                                                                   
                            </v-form>
                         </v-row>
                            <v-row class="justify-end" style="margin-right: 31px; margin-bottom: 10px;">     
                                <v-btn color="primary" @click="submitForm">Cargar Documentos</v-btn>
                            </v-row>

                            <!-- Imagen de carga -->
                            <div v-if="loading" class="loading-container">
                                <p>Cargando, por favor espera...</p>
                            </div>

                            <v-snackbar v-model="snackbar" :timeout="timeout" :color="snackbarColor" top>
                                {{ snackbarMessage }}
                            </v-snackbar>   
                    </v-card>
                </v-container>
            </v-layout>
        </v-container>
    </template>
    
    <!-- #################################################################################### -->
    <!-- ###### Sección de Scripts                                                     ###### -->
    <!-- #################################################################################### -->
    <script>
      import "jquery-ui/ui/widgets/resizable";
      import { mapState } from "vuex"; //  computed: { ...mapState(["auth"]), ...
      import { ValidationProvider } from 'vee-validate';
      import { Role } from "../../../../router/role.js";
    
      export default{
        components: {
            ValidationProvider,
        },
        props: {
            activeForm: Boolean
        },
        data() {
          return {
            name: 'Documents',
            formValidarCargarRips: true,
            selectedRipFile: null, // Archivo RIPS seleccionado
            selectedRipFiles: [],  // Lista de archivos RIPS seleccionados
            uploadedFile: null,    // Archivo cargado por el usuario
            ripFiles: [
                { text: 'AF - Archivo de las transacciones', value: 'fileTransaccion' },
                { text: 'US - Archivo de los usuarios de los servicios de salud', value: 'fileUsuario' },
                { text: 'AD - Archivo de descripción agrupada de los servicios de salud prestados', value: 'fileDescripcionAgrupada' },
                { text: 'AC - Archivo de consulta', value: 'fileConsulta' },
                { text: 'AP - Archivo de procedimientos', value: 'fileProdedimientos' },
                { text: 'AH - Archivo de hospitalización', value: 'fileHospitalizacion' },
                { text: 'AU - Archivo de urgencias', value: 'fileUrgencia' },
                { text: 'AN - Archivo de recién nacidos', value: 'fileRecienNacidos' },
                { text: 'AM - Archivo de medicamentos', value: 'fileMedicamentos' },
                { text: 'AT - Archivo de otros servicios', value: 'fileOtrosArchivos' }
            ],
            menuPeriodoCargaVisible: false, // Propiedad booleana para controlar la visibilidad del menú
            prestador: null,    // Propiedad para el v-model del select de prestadores
            periodoCarga: null, // Propiedad para el v-model del date picker
            activePicker: null, // Propiedad para el active-picker.sync
            roles: Role,
            userRoles: {},
            mostrarContenido: true, // Inicialmente visible
            formData: new FormData(),
            encabezado: {
                idPrestador: null,
                periodoCarga: null,
                usuario: "",
            },
            loading: false, // Estado de carga
            snackbar: false,
            snackbarMessage: '',
            timeout: 6000, // Tiempo en milisegundos que la notificación estará visible
            snackbarColor: 'error', // Color inicial del snackbar
            documentos: {},
            documentosTemporales: {},
            formatoIncorrecto: {
                rip: '',
            },
            sizeText: null,
            documentoSeleccionado: '',
            tipoDocumentoActual: '',
            componenteDocumento: '',
            visibility: false,
            mostrarModal: false,
            modalTitulo:'',
            listaIps: []
          };
        },

        computed: {
            ...mapState(["auth"]),
        },
    
        methods: {
            /**
            * Agrega un archivo RIP seleccionado a la lista de archivos seleccionados (`selectedRipFiles`).
            * 
            * Si `selectedRipFile` no es nulo y no está ya presente en `selectedRipFiles` (basado en su valor),
            * se añade a la lista con `uploadedFile` inicializado a `null`.
            * 
            * Después de agregar el archivo, se reinicia la selección (`selectedRipFile` se establece en `null`).
            */
            addRipFile() {
                if (this.selectedRipFile && !this.selectedRipFiles.some(file => file.value === this.selectedRipFile.value)) {
                    this.selectedRipFiles.push({ ...this.selectedRipFile, uploadedFile: null });
                    this.selectedRipFile = null; // Reset the selection
                }
            },

            /**
            * Guarda la fecha seleccionada en el menú de selección de período de carga.
            * 
            * Este método utiliza una referencia (`ref`) llamada `menuPeriodoCarga` para acceder a un componente
            * específico y llama a su método `save` con la fecha proporcionada como argumento.
            * 
            * @param {Date} date - La fecha seleccionada que se va a guardar.
            */
            savePeriodoCargaVisible(date) {
                this.$refs.menuPeriodoCarga.save(date);
            },

            /**
            * Envía el formulario de carga de archivos RIPS.
            * 
            * Este método prepara y envía los datos del formulario, incluyendo el encabezado y los archivos seleccionados,
            * a un servidor mediante una solicitud HTTP POST. También maneja los casos de éxito y error al enviar los datos.
            */
            async submitForm() {
                this.loading = true;
                // Verifica si el usuario tiene el rol interno
                const esRolInterno = this.userRoles.includes('MallaValidadora_SolicitudServicio_FormularioSolicitud_Interno');
                // Añadir los campos del encabezado
                if (this.prestador && this.prestador.id_prestador) {
                    this.encabezado.idPrestador = esRolInterno ? this.prestador.id_prestador : 0;
                } else {
                    this.encabezado.idPrestador = 0;
                }
                this.encabezado.periodoCarga = this.periodoCarga;
                if (this.auth && this.auth.username) {
                    this.encabezado.usuario = this.auth.username;
                }
                // Añadir el archivo de control (CT)
                const controlFile = this.$refs.refRipsDocument.internalValue;
                if (controlFile) {
                    this.formData.append('fileControl', controlFile);
                }
                // Añadir los archivos seleccionados
                this.selectedRipFiles.forEach( file => {
                    if (file.uploadedFile) {
                    this.formData.append(`${file.value}`, file.uploadedFile);
                    }
                });

                let obj = JSON.stringify(this.encabezado);
                this.formData.append('encabezadoRips', new Blob([obj], { type: 'application/json' }));

                    // Imprimir el contenido de FormData
                    for (let [key, value] of this.formData.entries()) {
                        console.log(key, value);
                    }

                    console.log(obj)

                this.$http.post("msa-localapp/api/v1/rutas/rips/guardar-informacion", this.formData,
                { headers: { 'Content-Type': 'multipart/form-data' } 
                }).then(() => {
                    this.showSuccess("RIPS enviados correctamente");
                    this.loading = false;
                    setTimeout(() => {
                        window.location.reload();
                    }, 5000);
                }).catch((error) => {
                    this.loading = false;
                    // Extraer y mostrar el mensaje de error específico
                    let errorMessage = "Ocurrió un error inesperado al enviar los RIPS";
                    if (error.response && error.response.data && error.response.data.mensaje) {
                        if (Array.isArray(error.response.data.mensaje)) {
                            errorMessage = error.response.data.mensaje.join(', ');
                        } else {
                            errorMessage = error.response.data.mensaje;
                        }
                    }
                    
                    this.showError(errorMessage);
                })
            },
            /**
             * Muestra un mensaje de éxito en un snackbar.
             * 
             * Este método configura y muestra un snackbar con un mensaje de éxito específico,
             * cambiando el color del snackbar a verde para indicar una operación exitosa.
             *
             * @param {string} message - El mensaje de éxito a mostrar en el snackbar.
             */
            showSuccess(message) {
                this.snackbarMessage = message;
                this.snackbarColor = 'success';
                this.snackbar = true;
            },

            /**
             * Muestra un mensaje de error en un snackbar.
             * 
             * Este método configura y muestra un snackbar con un mensaje de error específico,
             * cambiando el color del snackbar a rojo para indicar que ha ocurrido un error.
             *
             * @param {string} message - El mensaje de error a mostrar en el snackbar.
             */
            showError(message) {
                this.snackbarMessage = message;
                this.snackbarColor = 'error';
                this.snackbar = true;
            }, 
            
            /**
             * Dispara el evento de clic en el input de archivo.
             * 
             * Este método simula un clic en el input de archivo referenciado, 
             * activando la interfaz para que el usuario seleccione un archivo.
             * Es útil cuando se desea iniciar la carga de archivos mediante 
             * un botón u otro elemento de la interfaz de usuario.
             */
            triggerFileInput() {
                this.$refs.fileInput.click(); // Simula un clic en el input de archivo
            }, 
            /**
             * Maneja la carga de un directorio de archivos, mapea cada archivo a un objeto 
             * con la información relevante y lo almacena en `selectedRipFiles`.
             *
             * @param {Event} event - Evento de cambio disparado por la selección de archivos.
             * @property {FileList} event.target.files - Lista de archivos seleccionados.
             */
            handleDirectoryUpload(event) {
                const files = event.target.files;
                const filesArray = Array.from(files);          
                this.selectedRipFiles = filesArray.map(file => {
                const matchingRipFile = this.ripFiles.find(ripFile => file.name.includes(ripFile.text.split(' ')[0]));
                if (matchingRipFile) {
                    return {
                    text: matchingRipFile.text,
                    uploadedFile: file,
                    value: matchingRipFile.value,
                    };
                }
                return null;
                }).filter(file => file !== null);
            },

            /**
             * Muestra el documento seleccionado en el modal correspondiente.
             * 
             * @param {string} type - Tipo de documento a mostrar ('text').
             */
             mostrarDocumento(text) {
                this.modalTitulo = `Verifique que el documento esté acorde con la norma.`;

                // Buscar el archivo en selectedRipFiles basado en `text`
                const fileEntry = this.selectedRipFiles.find(file => file.text === text);

                if (fileEntry && fileEntry.uploadedFile) {
                    const file = fileEntry.uploadedFile;
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        this.documentoSeleccionado = e.target.result;
                        this.componenteDocumento = 'txt';
                        this.mostrarModal = true;
                    };
                    reader.readAsText(file);
                } else {
                    this.documentoSeleccionado = 'Archivo no encontrado o no cargado.';
                    this.componenteDocumento = '';
                    this.mostrarModal = true;
                }
            },

            /**
             * Abre el modal para mostrar el documento correspondiente.
             * 
             * Este método establece la visibilidad del modal y llama a 
             * `mostrarDocumento` con el parámetro proporcionado para 
             * cargar y visualizar el documento.
             * 
             * @param {string} text - El texto que representa el tipo o 
             * identificación del documento a mostrar.
             */
            abrirDocumento(text) {
                this.visibility = true;
                this.mostrarDocumento(text);
            },
            borrarDocumento(tipo) {
                this.documentos[tipo] = null;
            },

            /**
             * Resetea el archivo subido en selectedRipFiles para el documento especificado.
             * 
             * Este método encuentra el archivo correspondiente en el array selectedRipFiles
             * usando el texto proporcionado y resetea su propiedad uploadedFile a null.
             * 
             * @param {string} text - El texto que identifica el archivo en selectedRipFiles
             * y que se utilizará para buscar y resetear el archivo.
             */
             borrarDocumentoIcono(text) {
                // Encuentra el archivo en selectedRipFiles
                const fileIndex = this.selectedRipFiles.findIndex(file => file.text === text);

                // Si se encuentra el archivo, resetea su uploadedFile a null
                if (fileIndex !== -1) {
                    const file = this.selectedRipFiles[fileIndex];
                    file.uploadedFile = null;

                    // Eliminar del FormData si ya había sido añadido
                    if (this.formData.has(file.value)) {
                        this.formData.delete(file.value);
                    }

                    // También puedes remover el archivo del array selectedRipFiles si es necesario
                    // this.selectedRipFiles.splice(fileIndex, 1);
                }
            },

            /**
             * Cierra el modal actual.
             * 
             * Este método establece la propiedad mostrarModal en false, lo que oculta el modal.
             */
            cerrarModal() {
                this.mostrarModal = false;
            },

            getListaPrestadores() {
                this.$http.get("msa-localapp/api/GetListaIPSPrestadores").then((result) => {
                    this.listaIps = result.data;
                })
            },

            mostrarListaIps(item) {
                return item?.nombre_prestador
            },
               
        }, 
    
        mounted() {

        },
        created() {
            this.userRoles = this.auth.roles;	
            this.getListaPrestadores();
        }
    
      };
    </script>
    
    <!-- #################################################################################### -->
    <!-- ###### Sección de Estilos                                                     ###### -->
    <!-- #################################################################################### -->
    <style scoped>
        .search-input {
            width: 100%;
            border-radius: 20px;
            border: 1px solid #ccc;
            padding: 10px;
            box-sizing: border-box;
        }
        ::v-deep .elevation div table thead tr th {
            background-color: rgb(223, 223, 223) !important;
        }
    
        ::v-deep .elevation div table thead tr th span {
            font-weight: bold;
            color: black !important;
        }
        .center-icon {
            text-align: center; /* Centrar horizontalmente */
            vertical-align: middle; /* Centrar verticalmente */
        }
        .blueGlobal {
            background-color: blue;
            /* Estilos adicionales para el encabezado */
        }
    
        /* estilos para el header que contiene input de busqueda y boton de cargar carta de agotado */
        .search-bar {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 5px;
            background-color: white;
        }
        
        .centered {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
        }
        
        .upload-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
        }
        
        .upload-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        }
        
        .upload-message {
        margin-bottom: 10px;
        }
        
        .upload-btn {
        width: 300px;
        background-color: green;
        
        }
        
        .divCard{
            margin-top: 5px;
        }
        
        .container {
        margin-top: -20px; /* Ajusta el valor según tus necesidades */
        overflow-x: hidden;
        }
        
        .boxPdf {
        overflow: auto;
        height: 100vh;
        }
        
        .boxPdf>span {
        width: 100%;
        }
        
        .input-file{
            padding: 21px;
            margin: 20p;
            background: aliceblue;
            margin-top: -22px;
            width: 100%;
        }
        
        .estado-button {
            cursor: pointer;
            background-color: #caced3;
            color: gray;
            padding: 5px 10px;
            border-radius: 4px;
            font-weight: normal;
            font-size: 11px;
        }
        
        .estado-button:hover {
        background-color: green;
        color: white
        }
        
        #period{
            width: 113px;
            padding: 5px;
            border: 1px solid #d6d5e0;
            border-radius: 7px;
            margin-left: 10px;
        }
        #ips_origen_malla{
            width: 113px;
            padding: 5px;
            border: 1px solid #d6d5e0;
            border-radius: 7px;
            margin-left: 10px;
        }
        
        .file-input-container {
            display: flex;
            flex-direction: column;
        }

        .file-input-row {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
        }

        .file-input-label {
            margin-right: 10px;
            min-width: 150px;
            text-align: right;
        }

        .file-input {
            flex: 1;
        }

        .loading-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0.8);
            z-index: 9999;
        }

        .loading-container img {
            width: 100px;
            height: 100px;
        }

        .loading-container p {
            font-size: 1.2em;
            margin-top: 10px;
        }
        .full-width {
            width: 100%;
        }
        .text-label{
            background: #eceff1;
            height: 30%;
            margin-top: 12px;
            border-radius: 7px;
            padding: 8px;
            padding-left: 23px;
        }
        .icon-btn {
          margin-top: -26px; /* Ajusta este valor según sea necesario */
        }
    </style>
    