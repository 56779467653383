<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: John David Vásquez Serna                                      ###### -->
<!-- ###### @date: Junio 2024                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <div class="contenido">
    <!-- Descripción -->
    <div class="d-flex align-center mb-3">
      <v-icon color="blue darken-4" size="55">library_books</v-icon>
      <span class="ms-4 descripcion">
        Herramienta diseñada para generar reportes en formato CSV
        <br> de los paquetes entregados por la transportadora en un periodo de hasta un mes.
      </span>
    </div>
    <v-divider color="#9E9E9" class="mb-4"></v-divider>

    <!-- Filtros para buscar un reporte de tracting domicilio-->
    <section>
      <v-container class="container">

        <v-row v-if="userRoles.includes(roles.Reportes_Logistica_Tracking_Detalles)">
          <v-col cols="12" sm="12" md="12" align="center" class="pt-0 d-flex justify-center">
            <span class="pr-3">Tipo de reporte: </span>
            <v-radio-group v-model="tipoReporte" row hide-details class="ma-0 pa-0 d-flex align-center"> 
              <v-radio label="Auditoria" value="A"></v-radio>
              <v-radio label="Detallado" value="D"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <v-row v-if="tipoReporte">
          <!-- Campo para filtrar por fecha inicial -->
          <v-col cols="12" sm="3" md="3" class="pa-0">
            <v-menu ref="menuFechaInicio" v-model="menuFechaInicio" transition="scale-transition" min-width="290px"
              :close-on-content-click="false" :return-value.sync="fechaInicialCalendario" :nudge-top="25" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field ref="refFechaInicial" v-model="fechaInicialCalendario" label="Fecha inicial *"
                  v-bind="attrs" v-on="on" :disabled="inProgress === true" outlined dense readonly
                  :rules="rules.agregarRules" class="pr-1 pl-1">
                </v-text-field>
              </template>
              <v-date-picker v-model="fechaInicialCalendario" :first-day-of-week="1" :max="currentDate" locale="es-co"
                no-title scrollable @input="saveFechaInicial">
              </v-date-picker>
            </v-menu>
          </v-col>

          <!-- Campo para filtrar por fecha final -->
          <v-col cols="12" sm="3" md="3" class="pa-0">
            <v-menu ref="menuFechaFinal" v-model="menuFechaFinal" transition="scale-transition" min-width="290px"
              :close-on-content-click="false" :return-value.sync="fechaFinalCalendario" :nudge-top="25" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field ref="refFechaFinal" v-model="fechaFinalCalendario" label="Fecha final *" v-bind="attrs"
                  v-on="on" :disabled="inProgress === true" outlined dense readonly :error-messages="rangoFechas"
                  :rules="rules.agregarRules" class="pr-1 pl-1">
                </v-text-field>
              </template>
              <v-date-picker v-model="fechaFinalCalendario" :first-day-of-week="1" :max="currentDate" locale="es-co"
                no-title scrollable @input="saveFechaFinal">
              </v-date-picker>
            </v-menu>
          </v-col>

          <!-- Filtro por tipo de entrega -->
          <v-col cols="12" sm="3" md="3" class="pa-0">
            <v-autocomplete ref="filtroEntrega" v-model="filtro.tipoEntrega" label="Tipo de pedido" outlined dense
              :items="tiposEntrega" :menu-props="{ offsetY: true, maxHeight: 200 }"
              @keydown="tabulador($event, 'tipoEntrega', 'filtroEntrega')" :disabled="inProgress === true"
              no-data-text="Sin resultados" :rules="rules.agregarRules" class="pr-1 pl-1">
            </v-autocomplete>
          </v-col>

          <!-- campo para buscar y filtrar por bodega -->
          <v-col cols="12" sm="3" md="3" class="pa-0">
            <v-text-field v-model="filtro.bodega" outlined dense readonly label="Bodega" class="pr-1 pl-1"
              :disabled="inProgress === true" @mousedown.prevent="dialogoBodegas = true" :clearable="true"
              @click:clear="borrarBodegas()">
            </v-text-field>
          </v-col>

        </v-row>
      </v-container>

      <!-- Botón de generar reporte -->
      <v-sheet v-if="tipoReporte" class="d-flex align-center justify-center" :color="disableReport ? 'grey lighten-4' : 'green lighten-5'"
        height="65px" width="100%" rounded>
        <v-btn v-if="inProgress === false" color="success" @click="tipoReporte === 'D' ? reporteDetallado() : reporteAuditoria()" :disabled="disableReport">
          <v-icon>download</v-icon>
          generar reporte
        </v-btn>
        <div v-else class="d-flex justify-center">
          <v-icon class="rotate-animation" color="#0d47a1" large>rotate_right</v-icon>
          <span class="d-flex align-center font-weight-black primary--text ms-2">CARGANDO</span>
        </div>
      </v-sheet>
    </section>

    <!-- Alerta cuando no se encuentran resultados -->
    <v-alert class="mt-5" icon="error" color="error" transition="scale-transition" :value="alert" outlined>
      <h3>No se encontraron resultados.</h3>
      <p class="no-resultados">Los datos ingresados no coinciden con ningún registro.
        <br>Verifica que los filtros sean correctos o intenta una búsqueda diferente.
      </p>
    </v-alert>

    <v-dialog v-model="dialogoBodegas" transition="dialog-bottom-transition" max-width="40rem" persistent>
      <v-card>
        <v-card-title class="d-flex justify-center">
          <span>Bodegas</span>
        </v-card-title>
        <v-card-text>
          <div class="filtrosBodega">
            <v-text-field class="me-2" outlined dense hide-details label="Código"
              v-model="buscarCodigoBodega"></v-text-field>
            <v-text-field outlined dense hide-details label="Nombre" v-model="buscarNombreBodega"></v-text-field>
          </div>

          <v-data-table :loading="loading" fixed-header :headers="headersBodegas" :items="listaDeBodegas"
            :page.sync="pagina" :items-per-page.sync="itemsPorPagina" :server-items-length="totalPaginas"
            class="mt-2 estiloFilas" height="32vh" :footer-props="footerPropsTabla" :single-select="singleSelect"
            show-select>
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="item in items" :key="item.id">
                  <td>
                    <v-checkbox :value="item" v-model="bodegaSeleccionada" :input-value="isSelected(item.codigoBodega)"
                      @change="selectBodega(item)"></v-checkbox>
                  </td>
                  <td>{{ item.codigoBodega }}</td>
                  <td>{{ item.nombreBodega }}</td>
                </tr>
              </tbody>
            </template>
            <template v-slot:footer.page-text="items">
              {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
            </template>
          </v-data-table>

          <div v-if="bodegasSeleccionadas.length > 0" class="mt-2 mb-3" style="overflow-y: auto; max-height: 20vh;">
            <v-chip-group column>
              <v-chip v-for="codigo in bodegasSeleccionadas" :key="codigo" color="primary" text-color="white" close
                @click:close="remove(codigo)">{{ codigo }}</v-chip>
            </v-chip-group>
          </div>

          <div class="d-flex justify-end">
            <v-btn class="me-2" text color="red" @click="cerrarDialogoBodegas()">CANCELAR</v-btn>
            <v-btn color="success" :disabled="bodegasSeleccionadas.length < 1"
              @click="confirmarBodegas()">CONFIRMAR</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Contenedor para mostrar los mensajes de error -->
    <div v-if="mostrarNotificacion" :class="['notificacion', tipoNotificacion]">
      <span><v-icon :color="tipoNotificacionColor" :class="iconoNotificacionClase"
          class="rotate-animation-notification size">{{ iconoNotificacion }}</v-icon></span>
      <span>{{ mensajeNotificacion }}</span>
    </div>

  </div>
</template>
<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
import { mapState } from "vuex";
import { Role } from "@/router/role.js";
import Papa from 'papaparse'

/**
 * Esta función toma una matriz de matrices (una matriz bidimensional) 
 * donde cada sub-matriz (o fila) representa una fila de datos en el CSV.
 * Cada elemento dentro de las sub-matrices se une con un delimitador 
 * (en este caso un punto y coma ';') y cada fila se une con un salto de línea.
 * 
 * @param {Array} data - Matriz bidimensional que contiene los datos.
 * @returns {string} - Cadena representando los datos en formato CSV.
 */
function arrayToCSV(data) {
  return data.map(row => row.join(";")).join("\n");
}

/**
 * Esta función crea un archivo en formato CSV a partir de una cadena 
 * y luego inicia una descarga en el navegador del usuario para ese archivo.
 * 
 * @param {string} csvData - Cadena que contiene los datos en formato CSV.
 * @param {string} filename - Nombre que se asignará al archivo descargado.
 */
function downloadCSV(csvData, filename) {
  const blob = new Blob([csvData], { type: "text/csv" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export default {
  name: "ReportesTrackingEntregas",
  data() {
    return {
      filtro: {
        fechaInicial: '',
        fechaFinal: '',
        tipoEntrega: null,
        bodega: [],
      },
      menuFechaInicio: false,
      menuFechaFinal: false,
      dialogoBodegas: false,
      listaDeBodegas: [],
      buscarCodigoBodega: '',
      buscarNombreBodega: '',
      pagina: 1,
      totalPaginas: 0,
      itemsPorPagina: 10,
      loading: false,
      singleSelect: true,
      headersBodegas: [
        { text: "CÓDIGO", value: 'codigoBodega', width: "14%", align: 'left', sortable: false },
        { text: 'NOMBRE', value: 'nombreBodega', width: "76%%", align: 'left', sortable: false },
      ],
      footerPropsTabla: {
        'items-per-page-options': [10, 20, 30, 40, 50],
        'items-per-page-text': 'Items por página:',
        'show-current-page': true,
        'show-first-last-page': true,
      },
      bodegasSeleccionadas: [],
      bodegaSeleccionada: [],
      mensajeError: '',
      alert: false,
      rangoFechas: '',
      inProgress: false,
      userRoles: {},
      roles: Role,
      fechaInicialCalendario: '',
      fechaFinalCalendario: '',
      rules: {
        agregarRules: [
          v => !!v || "Este campo es requerido.",
        ],
      },
      tiposEntrega: [
        { text: 'Cliente', value: 'C' },
        { text: 'Interno', value: 'I' },
        { text: 'Domicilio', value: 'D' }
      ],
      truncate: '',
      mostrarNotificacion: false,
      mensajeNotificacion: "",
      tipoNotificacion: "",
      tipoNotificacionColor: "",
      iconoNotificacion: "",
      iconoNotificacionClase: "",
      tipoReporte: null,
    }
  },
  created() {
    this.tipoReporte = 'A';
    this.userRoles = this.auth.roles;
    this.pageBodegas();
  },
  computed: {
    ...mapState(["auth", "enterprise"]),
    /**
     * Función computada que devuelve la fecha actual en formato ISO (YYYY-MM-DD)
     * para que en el v-date-picker no seleccione fechas posteriores a las del día actual. 
     */
    currentDate() {
      return new Date().toISOString().split('T')[0];
    },
    /** Función usada para deshabilitar o habilitar el botón para generar un reporte de acuerdo a las propiedades de los filtros. */
    disableReport() {
      const tipEnt = this.filtro.tipoEntrega === null ? '' : this.filtro.tipoEntrega;
      return this.filtro.fechaInicial === '' ||
        this.filtro.fechaFinal === '' ||
        tipEnt === '' ||
        this.rangoFechas !== '' ||
        this.menuFechaInicio === true ||
        this.menuFechaFinal === true;
    },
  },
  watch: {
    /**
     * Este watcher se activa cuando alguna propiedad del objeto `filtro` cambia.
     * Es "deep", por lo que observa cambios en propiedades anidadas dentro de `filtro`.
     * Al detectar cambios, se encarga de desactivar la alerta (`alert` se establece en false).
     */
    filtro: {
      deep: true,
      handler() {
        this.alert = false;
      }
    },
    /** Si hay cambios en la variable "pagina", se llama a la función pageBodegas() */
    pagina: function () {
      this.pageBodegas();
    },
    /** Si hay cambios en la variable "itemsPorPagina", se actualiza la variable "pagina" a 1 y se llama a la función pageBodegas() */
    itemsPorPagina: function () {
      this.pagina = 1;
      this.pageBodegas();
    },
    'buscarCodigoBodega': function () {
      this.pagina = 1;
      this.pageBodegas();
    },
    'buscarNombreBodega': function () {
      this.pagina = 1;
      this.pageBodegas();
    },
  },
  methods: {
    /**
     * Este método verifica que la fecha inicial (`fechaUno`) sea anterior a la fecha final (`fechaDos`). Además, 
     * se asegura de que el rango de fechas seleccionadas no exceda un mes. Si alguna de estas condiciones no se cumple, 
     * establece un mensaje de error correspondiente y marca el estado como inválido (`invalid = true`).
     */
    validacionesFechas() {
      const fechaUno = new Date(`${this.filtro.fechaInicial}T00:00:00-05:00`);
      const fechaDos = new Date(`${this.filtro.fechaFinal}T00:00:00-05:00`);

      if (fechaUno > fechaDos) {
        this.rangoFechas = 'La fecha final debe ser mayor a la fecha inicial';
        return;
      }

      let fechaMaximaPermitida = new Date(fechaUno);
      fechaMaximaPermitida.setMonth(fechaMaximaPermitida.getMonth() + 1);

      if (fechaUno.getDate() === 1) {
        fechaMaximaPermitida.setDate(0);
      }

      if (fechaDos > fechaMaximaPermitida) {
        this.rangoFechas = 'Las fechas seleccionadas no deben exceder un mes';
      } else {
        this.rangoFechas = '';
      }
    },
    /** Guarda la fecha inicial seleccionada en el calendario y realiza las validaciones de rango de fechas. */
    saveFechaInicial() {
      this.$refs.menuFechaInicio.save(this.fechaInicialCalendario);
      this.filtro.fechaInicial = this.fechaInicialCalendario;
      this.validacionesFechas();
    },
    /** Limpia la fecha inicial seleccionada y restablece los campos relacionados. */
    cleanFechaInicial() {
      this.fechaInicialCalendario = '';
      this.$refs.menuFechaInicio.save('');
      this.filtro.fechaInicial = '';
    },
    /** Guarda la fecha final seleccionada en el calendario y realiza las validaciones de rango de fechas. */
    saveFechaFinal() {
      this.$refs.menuFechaFinal.save(this.fechaFinalCalendario);
      this.filtro.fechaFinal = this.fechaFinalCalendario;
      this.validacionesFechas();
    },
    /** Limpia la fecha final seleccionada y restablece los campos relacionados. */
    cleanFechaFinal() {
      this.fechaFinalCalendario = '';
      this.$refs.menuFechaFinal.save('');
      this.filtro.fechaFinal = '';
    },
    /**
     * Verifica si una bodega está seleccionada.
     * 
     * @param {string} codigoBodega - El código de la bodega.
     * @returns {boolean} - Devuelve true si la bodega está seleccionada, de lo contrario, false.
     */
    isSelected(codigoBodega) {
      return this.bodegasSeleccionadas.includes(codigoBodega);
    },
    /**
     * Selecciona o deselecciona una bodega.
     * 
     * @param {Object} item - El objeto de la bodega que contiene el código de la bodega.
     */
    selectBodega(item) {
      const index = this.bodegasSeleccionadas.indexOf(item.codigoBodega);
      if (index > -1) {
        this.bodegasSeleccionadas.splice(index, 1);
      } else {
        this.bodegasSeleccionadas.push(item.codigoBodega);
      }
    },
    /**
     * Obtiene y pagina la lista de bodegas desde el backend.
     * Actualiza la lista de bodegas seleccionadas según los filtros aplicados.
     * 
     * @async
     */
    async pageBodegas() {
      this.listaDeBodegas = [];
      this.bodegaSeleccionada = [];
      try {
        const response = await this.$http.get(`msa-reports/api/bodegas/pageBodegas`, {
          params: {
            page: this.pagina - 1,
            size: this.itemsPorPagina,
            idEmpresa: this.enterprise.code,
            codigoBodega: this.buscarCodigoBodega,
            nombreBodega: this.buscarNombreBodega,
          }
        });
        this.listaDeBodegas = response.data.content;
        this.totalPaginas = response.data.totalElements;
        if (this.filtro.bodega && this.filtro.bodega.length > 0) {
          this.bodegaSeleccionada = this.listaDeBodegas.filter(item => this.filtro.bodega.includes(item.codigoBodega));
          this.bodegasSeleccionadas = this.filtro.bodega;
        } else {
          this.bodegaSeleccionada = this.listaDeBodegas.filter(item => this.bodegasSeleccionadas.includes(item.codigoBodega));
        }
      } catch (error) {
        this.manejarError(error);
      }
    },
    /**
     * Elimina una bodega de las listas de bodegas seleccionadas.
     * 
     * @param {string} codigo - El código de la bodega a eliminar.
     */
    remove(codigo) {
      const indexSeleccionadas = this.bodegasSeleccionadas.indexOf(codigo);
      const indexSeleccionada = this.bodegaSeleccionada.findIndex(item => item.codigoBodega === codigo);

      if (indexSeleccionadas > -1) {
        this.bodegasSeleccionadas.splice(indexSeleccionadas, 1);
      }

      if (indexSeleccionada > -1) {
        this.bodegaSeleccionada.splice(indexSeleccionada, 1);
      }
    },
    /**
     * Confirma la selección de bodegas y actualiza el filtro.
     */
    confirmarBodegas() {
      this.filtro.bodega = this.bodegasSeleccionadas;
      this.cerrarDialogoBodegas();
    },
    /**
     * Borra todas las bodegas seleccionadas y cierra el diálogo de selección de bodegas.
     */
    borrarBodegas() {
      this.filtro.bodega = [];
      this.cerrarDialogoBodegas();
    },
    /**
     * Función reutilizable para agregar días a una fecha.
     * @param {Date} date - La fecha de inicio.
     * @param {number} days - La cantidad de días a agregar.
     * @returns {Date} - La nueva fecha con los días agregados.
     */
    addDays(date, days) {
      let result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
    /**
     * Solicita y procesa los datos de los paquetes entregados para la auditoria basándose en los filtros. 
     * La respuesta esperada es un blob que contiene datos en formato CSV. Estos datos son luego 
     * procesados y convertidos a un archivo .csv para su descarga.
     */
    reporteAuditoria() {
      this.inProgress = true;

      let startDate = new Date(this.filtro.fechaInicial);
      let endDate = new Date(this.filtro.fechaFinal);
      let requests = [];
      while (startDate <= endDate) {
        let tempEndDate = this.addDays(startDate, 4);
        if (tempEndDate > endDate) {
          tempEndDate = endDate;
        }

        let request = this.$http.get(`msa-reports/api/trackingDomicilio/auditoria`, {
          responseType: 'blob',
          params: {
            idEmpresa: this.enterprise.code,
            fechaInicial: startDate.toISOString().split('T')[0],
            fechaFinal: tempEndDate.toISOString().split('T')[0],
            tipo: this.filtro.tipoEntrega,
            codigosBodegas: (this.filtro.bodega && this.filtro.bodega.length > 0) ? this.filtro.bodega.join(',') : '',
          }
        });

        requests.push(request);
        startDate = this.addDays(tempEndDate, 1);
      }
      let nombreArchivo = this.filtro.tipoEntrega === 'D' ? 'tracking_domicilio' : this.filtro.tipoEntrega === 'I' ? 'pedido_interno' : 'pedido_cliente';
      const tipo = 'para_auditoria';
      this.reporteTracking(requests, tipo, nombreArchivo);
    },
    /**
     * Solicita y procesa los datos de los paquetes entregados basándose en los filtros.
     * La respuesta esperada es un blob que contiene datos en formato CSV. Estos datos son luego 
     * procesados y convertidos a un archivo .csv para su descarga.
     */
    reporteDetallado() {
      this.inProgress = true;

      let startDate = new Date(this.filtro.fechaInicial);
      let endDate = new Date(this.filtro.fechaFinal);
      let requests = [];
      let range = this.filtro.tipoEntrega === 'C' ? 10 : this.filtro.tipoEntrega === 'I' ? 7 : 4;
      while (startDate <= endDate) {
        let tempEndDate = this.addDays(startDate, range);
        if (tempEndDate > endDate) {
          tempEndDate = endDate;
        }

        let request = this.$http.get(`msa-reports/api/trackingDomicilio/detalles`, {
          responseType: 'blob',
          params: {
            idEmpresa: this.enterprise.code,
            fechaInicial: startDate.toISOString().split('T')[0],
            fechaFinal: tempEndDate.toISOString().split('T')[0],
            tipo: this.filtro.tipoEntrega,
            codigosBodegas: (this.filtro.bodega && this.filtro.bodega.length > 0) ? this.filtro.bodega.join(',') : '',
          }
        });

        requests.push(request);
        startDate = this.addDays(tempEndDate, 1);
      }
      let nombreArchivo = this.filtro.tipoEntrega === 'D' ? 'a_detalle_tracking_domicilio' : this.filtro.tipoEntrega === 'I' ? 'pedido_interno' : 'pedido_cliente';
      const tipo = 'a_detalle'
      if (this.filtro.tipoEntrega === 'D') {
        this.checkCantidadReporteDomicilio(nombreArchivo);
      } else {
        this.reporteTracking(requests, tipo, nombreArchivo);
      }
    },
    /**
     * Verifica cada minuto si las cantidades entre la tabla DpCaent y ReporteEntrega coiciden.
     * Si coinciden, se detiene el intervalo y se procede a la generación del reporte de domicilios.
     * 
     * @param nombreArchivo - Nombre anexo del archivo
     */
    checkCantidadReporteDomicilio(nombreArchivo) {
      let attempts = 0;
      let maxAttempts = 6;

      let interval = setInterval(() => {
        this.cantidadesReporteDomicilio().then(booleanResults => {
          attempts++;
          let trueCount = booleanResults.filter(result => result === true).length;
          if (trueCount > booleanResults.length / 2 || attempts >= maxAttempts) {
            clearInterval(interval);
            this.trackingDomicilios(nombreArchivo);
          }
        }).catch(error => {
          this.manejarError(error);
          clearInterval(interval);
        });
      }, 60000);
    },
    /**
     * Realiza la validación de cantidades entre dos tablas en bloques de hasta 4 días.
     * Devuelve un array de booleanos indicando si las cantidades coinciden (true) o no (false).
     */
    cantidadesReporteDomicilio() {
      let startDate = new Date(this.filtro.fechaInicial);
      let endDate = new Date(this.filtro.fechaFinal);
      let requests = [];

      while (startDate <= endDate) {
        let tempEndDate = this.addDays(startDate, 4);
        if (tempEndDate > endDate) {
          tempEndDate = endDate;
        }

        let request = this.$http.get(`msa-reports/api/trackingDomicilio/cantidad`, {
          params: {
            idEmpresa: this.enterprise.code,
            fechaInicial: startDate.toISOString().split('T')[0],
            fechaFinal: tempEndDate.toISOString().split('T')[0],
            codigosBodegas: (this.filtro.bodega && this.filtro.bodega.length > 0) ? this.filtro.bodega.join(',') : '',
          }
        }).then(response => {
          return response.data;
        }).catch(() => false);

        requests.push(request);
        startDate = this.addDays(tempEndDate, 1);
      }
      return Promise.all(requests);
    },
    /**
     * Genera y descarga el reporte de domicilios en formato CSV, procesando las fechas en bloques de hasta 5 días.
     * También ejecuta el truncado de las tablas después de generar el reporte.
     * 
     * @param nombreArchivo - Nombre anexo del archivo
     */
    trackingDomicilios(nombreArchivo) {
      let startDate = new Date(this.filtro.fechaInicial);
      let endDate = new Date(this.filtro.fechaFinal);

      let requests = [];
      while (startDate <= endDate) {
        let tempEndDate = this.addDays(startDate, 5);
        if (tempEndDate > endDate) {
          tempEndDate = endDate;
        }

        let request = this.$http.get(`msa-reports/api/trackingDomicilio/domicilio`, {
          responseType: 'blob',
          params: {
            fechaInicial: startDate.toISOString().split('T')[0],
            fechaFinal: tempEndDate.toISOString().split('T')[0],
          }
        });

        requests.push(request);
        startDate = this.addDays(tempEndDate, 1);
      }
      Promise.all(requests)
        .then(responses => {
          const parsePromises = responses.map(response => response.data.text());

          Promise.all(parsePromises)
            .then(csvParts => {
              let combinedCSV = '';
              csvParts.forEach((csv, index) => {
                let lines = csv.trim().split('\n');

                if (index !== 0) {
                  lines.shift();
                }

                combinedCSV += lines.join('\n') + (index === csvParts.length - 1 ? '' : '\n');
              });

              Papa.parse(combinedCSV, {
                complete: (results) => {
                  const filename = `reporte_${nombreArchivo}_${this.filtro.fechaInicial.replace(/-/g, '_')}_al_${this.filtro.fechaFinal.replace(/-/g, '_')}.csv`;
                  const csvData = arrayToCSV(results.data);
                  if (results.data.length > 2) {
                    downloadCSV(csvData, filename);
                    this.clear();
                    this.$refs.refFechaInicial?.reset();
                    this.$refs.refFechaFinal?.reset();
                    this.cerrarDialogoBodegas();
                  } else {
                    this.alert = true;
                  }
                }
              });
              this.$http.post(`msa-reports/api/trackingDomicilio/truncate`)
                .then(response => {
                  this.truncate = response;
                }).catch(error => {
                  this.manejarError(error);
                })
            }).finally(() => {
              this.inProgress = false;
            });
        }).catch(error => {
          this.manejarError(error);
        })
    },
    /**
     * Convierte los resultados del reporte en un archivo .cvque se descarga automaticamente.
     * 
     * @param requests - resultado del reporte de pedidos (cliente o interno)
     * @param nombreArchivo - Nombre del archivo dependiendo el tipo de reporte.
     */
    reporteTracking(requests, tipo, nombreArchivo) {
      Promise.all(requests)
        .then(responses => {
          const parsePromises = responses.map(response => response.data.text());

          Promise.all(parsePromises)
            .then(csvParts => {
              let combinedCSV = '';
              csvParts.forEach((csv, index) => {
                let lines = csv.trim().split('\n');

                if (index !== 0) {
                  lines.shift();
                }

                combinedCSV += lines.join('\n') + (index === csvParts.length - 1 ? '' : '\n');
              });

              Papa.parse(combinedCSV, {
                complete: (results) => {
                  const filename = `reporte_${tipo}_${nombreArchivo}_${this.filtro.fechaInicial.replace(/-/g, '_')}_al_${this.filtro.fechaFinal.replace(/-/g, '_')}.csv`;
                  const csvData = arrayToCSV(results.data);
                  if (results.data.length > 2) {
                    downloadCSV(csvData, filename);
                    this.clear();
                    this.$refs.refFechaInicial?.reset();
                    this.$refs.refFechaFinal?.reset();
                    this.cerrarDialogoBodegas();
                  } else {
                    this.alert = true;
                  }
                }
              });
            }).finally(() => {
              this.inProgress = false;
            });
        }).catch(error => {
          this.manejarError(error);
        })
    },
    /**
     * Maneja el evento de presionar la tecla Tab para campos v-autocomplete.
     * Si se presiona la tecla Tab y el valor actual del modelo es null,
     * desenfoca el campo y establece el valor del modelo como una cadena vacía.
     *
     * @param {KeyboardEvent} event - El objeto del evento de teclado.
     * @param {string} model - El nombre de la propiedad del modelo.
     * @param {string} ref - El nombre de la referencia del campo v-autocomplete.
     */
    tabulador(event, model, ref) {
      if (event.key === 'Tab' && this.filtro[model] === null) {
        this.$refs[ref].blur();
        this.filtro[model] = '';
      }
    },
    /**
     * Limpia y reinicia el estado del formulario y sus validaciones.
     * Este método resetea las validaciones del formulario mediante el observer de VeeValidate.
     * Además, se encarga de limpiar las fechas seleccionadas, el tipo de transacción, el NIT de la transportadora y 
     * cualquier mensaje de error asociado a las fechas.
     */
    clear() {
      this.filtro.fechaFinal = '';
      this.filtro.fechaInicial = '';
      this.cleanFechaInicial();
      this.cleanFechaFinal();
      this.filtro.tipoEntrega = null;
      this.$refs.filtroEntrega?.reset();
      this.filtro.bodega = null;
      this.rangoFechas = '';
      this.alert = false;
    },
    /**
     * Cierra el diálogo de selección de bodegas y restablece los valores por defecto.
     * 
     * - Establece el estado del diálogo a falso.
     * - Limpia las listas de bodegas seleccionadas y la bodega actualmente seleccionada.
     * - Restablece los filtros de búsqueda y la configuración de paginación.
     * - Vuelve a cargar la lista de bodegas.
     */
    cerrarDialogoBodegas() {
      this.dialogoBodegas = false;
      this.bodegasSeleccionadas = [];
      this.bodegaSeleccionada = [];
      this.buscarCodigoBodega = '';
      this.buscarNombreBodega = '';
      this.pagina = 1;
      this.itemsPorPagina = 10;
      this.pageBodegas();
    },
    /**
     * Muestra una notificación global en el componente.
     *
     * @param {string} mensaje - Mensaje que se mostrará en la notificación.
     * @param {string} tipo - Tipo de la notificación (por defecto, "error").
     * @param {string} icono - Icono de la notificación (por defecto, "highlight_off").
     */
    mostrarNotificacionGlobal(mensaje, tipo, icono) {
      this.mostrarNotificacion = true;
      this.tipoNotificacion = tipo || "advertencia"; // Tipo predeterminado es "error"
      this.tipoNotificacionColor = this.obtenerColorNotificacion(this.tipoNotificacion);
      this.iconoNotificacion = icono || "highlight_off"; // Icono predeterminado es "highlight_off"
      this.mensajeNotificacion = mensaje;
      this.iconoNotificacionClase = this.obtenerClaseIcono(this.tipoNotificacion);

      // Cierra la notificación después de 5 segundos
      setTimeout(() => {
        this.cerrarNotificacion();
      }, 5000);
    },
    /**
     * Cierra la notificación
     */
    cerrarNotificacion() {
      this.mostrarNotificacion = false;
      this.mensajeNotificacion = "";
      this.tipoNotificacion = "";
    },
    /**
     * Obtiene el color correspondiente al tipo de notificación.
     * 
     * @param {string} tipo - Tipo de notificación.
     * @returns {string} - Color de la notificación.
     */
    obtenerColorNotificacion(tipo) {
      switch (tipo) {
        case "advertencia":
          return "#f80505";
        default:
          return "#f80505";
      }
    },
    /**
     * Obtiene la clase de icono correspondiente al tipo de notificación.
     * 
     * @param {*} tipo  - Tipo de notificación.
     * @returns {string} - Clase de icono.
     */
    obtenerClaseIcono(tipo) {
      switch (tipo) {
        case "advertencia":
          return "advertencia-icon";
        default:
          return "advertencia-icon";
      }
    },
    /**
     * Maneja errores y muestra notificaciones correspondientes.
     * 
     * @param {*} error - Objeto de error.
     */
    manejarError(error) {
      let mensajeError = "Error inesperado, contacta con el administrador.";
      if (error.response) {
        if (error.response.data && error.response.data.message) {
          mensajeError = error.response.data.message;
        } else if (error.response.data) {
          mensajeError = JSON.stringify(error.response.data);
        }
      }

      const maxLength = 200;
      if (mensajeError.length > maxLength) {
        mensajeError = mensajeError.substring(0, maxLength) + "...";
      }

      this.mostrarNotificacionGlobal(mensajeError, "advertencia", "highlight_off");
      this.inProgress = false;
    },
  }
}
</script>
<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
.contenido {
  padding: 1rem;
  width: 100%;
  height: 85vh;
}

.descripcion {
  color: rgb(64, 62, 62);
}

.no-resultados {
  font-size: 15px;
  margin: 0;
}

::v-deep .bodegas .theme--light.v-label {
  color: black !important;
  margin-left: 1rem !important;
}

::v-deep .bodegas .v-input--selection-controls__ripple {
  height: 0px !important;
  width: 0px !important;
}

::v-deep .bodegas .v-list-item--dense,
.v-list--dense .v-list-item {
  min-height: 28px !important;
}

.filtrosBodega {
  display: flex;
}

::v-deep .estiloFilas div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}

::v-deep .estiloFilas div table thead tr th span {
  font-weight: bold;
  color: black !important;
}

.estiloFilas tbody tr td {
  font-size: 14px !important;
}

::v-deep .estiloFilas .v-data-footer {
  width: 100% !important;
}

::v-deep .estiloFilas .v-data-footer__select .v-select {
  margin: 5px;
  margin-left: 10px;
}

::v-deep .estiloFilas .v-data-table__wrapper {
  border: 1px solid #f7f6f6;
}

.rotate-animation {
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.container {
  max-width: none !important;
  width: 100% !important;
}

.notificacion {
  position: fixed;
  top: 50px;
  right: 20px;
  padding: 15px;
  border-radius: 5px;
  z-index: 9999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: opacity 0.5s ease-in-out;
  width: 30vw;
}

.notificacion span:last-child {
  cursor: pointer;
  margin-right: 10px;
  padding-top: 3px;
  width: 80%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

.advertencia {
  background-color: #ffffffd7 !important;
  color: #f80505;
}

.advertencia-icon {
  color: #f80505;
}

.rotate-animation-notification {
  animation: rotate-notification 1s ease-in-out; 
}

@keyframes rotate-notification  {
  0% {
    transform: rotateX(180deg);
  }

  50% {
    transform: rotateX(0deg);
  }

  100% {
    transform: rotateX(180deg);
  }
}

.size {
  font-size: xxx-large;
  font-weight: bold;
}
</style>